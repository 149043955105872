import Modal from "antd/lib/modal/Modal";
import {connect} from "react-redux";
import {
    setInnerModal,
    setRowId,
    setValues,
    getInnerData,
    setInnerData,
    setSmallModal,
} from "../../Actions/AppActions";
import "./Modal.css";
import ModalTable from "../Tables/ModalTable/ModalTable";
import React, {useEffect, useState} from "react";
import SearchCom from "../Header/SearchCom";
import {RiCloseCircleFill} from "react-icons/ri";
import SecondActions from "../Actions/secondActions/SecondActions";
import SmallModal from "./SmallModal";

const InnerModal = ({
                        modal,
                        setInnerModal,
                        setSmallModal,
                        setRowId,
                        allData,
                        innerSmallModal,
                        setValues,
                        currentPage,
                        getInnerData,
                        innerData,
                        innerDataHead,
                        setInnerData,
                        loading,
                        values,
                    }) => {
    const [searchDataSource, setSearchDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    const [title, setTitle] = useState([]);

    const clearModal = () => {
        setInnerModal(false);
        setRowId(null);
    };

    useEffect(async () => {
        if (currentPage.forms?.find(f => f.type === "document")) {
            if (modal && currentPage.innerData) {
                await getInnerData([currentPage.innerData]);
            }
            const obj = currentPage.forms?.find(f => f.type === "document").inputs[0].innerModal;
            setTitle(obj.title);
            setColumns(obj.columns);
            if (allData[obj.allData]) {
                setSearchDataSource(allData[obj.allData]);
            }
            console.log("Refreshed... => InnerModal.js 53 qator")
        }
    }, [currentPage]);

    useEffect(() => {
        if (modal) {
            if (currentPage.innerDataHead) {
                getInnerData([currentPage.innerDataHead]);
            } else {
                getInnerData([currentPage.innerData]);
            }
        }
    }, [modal, setSmallModal]);

    useEffect(() => {
        if (currentPage.path === "/postupleniya") {
            setValues({
                ...values,
                postavshik: null
            })
        }
    }, [setSmallModal]);

    useEffect(() => {
        if (modal && currentPage.innerData) {
            // Model ga qarab filtrlab beradi
            if (innerData[currentPage.innerData]) {
                if (currentPage.path === "/zborka") {
                    let detal = "";
                    allData && allData.modeli.map(item => {
                        if (item.Id === values.model) {
                            detal = item;
                        }
                    })
                    let result = [];
                    innerData[currentPage.innerData].map(item => {
                        if (item.model === detal.nomi) {
                            result.push(item)
                        }
                    })
                    setSearchDataSource(result);
                } else {
                    setSearchDataSource(innerData[currentPage.innerData]);
                }
            }
        }
    }, [innerData, innerSmallModal, setSmallModal]);

    let customStyle = {
        backStyle: {
            backgroundColor: "#eee",
            padding: "10px",
            borderRadius: "2px",
        },
        flexBetween: {
            display: "flex",
            justifyContent: "space-between",
        }
    }
    return (
        <Modal
            centered
            title={title}
            visible={modal}
            footer={null}
            width="900px"
            onCancel={clearModal}
            closeIcon={<RiCloseCircleFill/>}>
            <div style={customStyle.backStyle}>
                <div style={customStyle.flexBetween}>
                    <SecondActions setColumns={setColumns}/>
                    <SearchCom columns={columns} setS={setSearchDataSource}/>
                </div>
                <ModalTable
                    innerTable={true}
                    columns={columns}
                    innerTableData={searchDataSource}/>
                <SmallModal setColumns={setColumns}/>
            </div>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        modal: state.app.innerModal,
        innerSmallModal: state.app.innerSmallModal,
        values: state.app.values,
        allData: state.app.allData,
        currentPage: state.app.currentPage,
        innerData: state.app.innerData,
        innerDataHead: state.app.innerDataHead,
        loading: state.app.loading,
    };
};

export default connect(mapStateToProps, {
    setValues,
    setSmallModal,
    getInnerData,
    setInnerModal,
    setInnerData,
    setRowId,
})(InnerModal);
