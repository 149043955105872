import {
    PHONE,
    SELECT,
    STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {RiUser2Fill} from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";

const Klienti = {
    title: "Клиенты",
    path: "/spravochnik/klienti",
    icon: <RiUser2Fill/>,
    type: SIMPLE,
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Наименование</div>,
            dataIndex: "fullName",
            key: "fullName",
            width: "152px",
        },
        {
            title: <div className="table_head">Телефон номер 1</div>,
            dataIndex: "tel1",
            key: "tel1",
            width: "122px",
        },
        {
            title: <div className="table_head">Телефон номер 2</div>,
            dataIndex: "tel2",
            key: "tel2",
            width: "158px",
        },
        {
            title: <div className="table_head">Электронная почта</div>,
            dataIndex: "email",
            key: "email",
            width: "141px",
        },
        {
            title: <div className="table_head">Адрес</div>,
            dataIndex: "address",
            key: "address",
            width: "94px",
        },
        {
            title: <div className="table_head">Социальный сеть</div>,
            dataIndex: "social_net",
            key: "social_net",
            width: "147px",
        },
    ],
    modalWidth: "900px",
    allData: [
        {value: "social_net", prop: "social_nets", get: "nomi"}
    ],
    forms: [
        {
            grid: "1fr 1fr 1fr",
            inputs: [
                {
                    label: "Наименование",
                    name: "fullName",
                    type: STRING,
                    autoGetFocus: true,
                    required: true,
                },
                {
                    label: "Телефон номер 1",
                    name: "tel1",
                    type: PHONE,
                    addBefore: "+998",
                    className: "phone-input",
                    required: true,
                },
                {
                    label: "Телефон номер 2",
                    name: "tel2",
                    type: PHONE,
                }
            ],
        },

        {
            grid: "1fr 1fr 1fr",
            inputs: [

                {
                    label: "Электронная почта",
                    name: "email",
                    type: STRING,
                },
                {
                    label: "Адрес",
                    name: "address",
                    type: STRING,
                },
                {
                    label: "Социальный сеть",
                    name: "social_net",
                    type: SELECT,
                    allData: {
                        type: "social_nets",
                        key: "nomi",
                        text: "nomi"
                    }
                },
            ],
        },
    ],
};
export default Klienti;
