import React, {useEffect, useRef} from "react";
import {
    RiAddLine,
    RiCloseLine,
    RiPencilLine,
} from "react-icons/ri";
import {Button, message, Popconfirm} from "antd";
import {useState} from "react";
import "./NewHeadActions.scss";
import {
    setInnerData,
    getMainTableData,
    setValues,
    setRowId,
    setCurrentPage, setSmallModal, setValues3, getInnerData, setRowId2, setInnerModal2, getInnerHeadData, getAllData,
} from "../../../Actions/AppActions";
import {connect} from "react-redux";
import fetchApi from "../../../Functions/fetchApi";
import {useHistory} from "react-router-dom";
import FetchApi from "../../../Functions/fetchApi";
import moment from "moment";

const NewHeadActions = ({
                            values,
                            getAllData,
                            setValues3,
                            setSmallModal,
                            setRowId2,
                            currentPage,
                            innerDataType,
                            rowId2,
                            allData,
                        }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const openAdd = async () => {
        setRowId2(null);
        // setValues3({});
        setSmallModal(true);
    };

    const openEdit = () => {
        if (rowId2) {
            let obj = allData && allData[innerDataType.dataType].find(d => d.Id === rowId2);
            setValues3(obj);
            setSmallModal(true);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };
    const handleDelete = async () => {
        let user = JSON.parse(window.localStorage.getItem("user"));
        let user_id = user ? user.id : null;

        if (rowId2) {
            setLoading(true);
            await fetchApi(innerDataType.mainData.path + "/" + rowId2, "DELETE", {
                user_id,
            }).then(res => {
                if (res && res.status === 200) {
                    setRowId2(null);
                    getAllData([innerDataType.dataType]);
                }
            });
            setVisible(false);
            setLoading(false);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };

    return (
        <div className="second-actions">
            <Button onClick={openAdd} type="primary" className="action_btn main-btn">
                <RiAddLine className="action_icon"/>
                <span className="action_text">Добавить</span>
            </Button>
            <Button onClick={openEdit} type="primary" className="action_btn edit-btn">
                <RiPencilLine className="action_icon"/>
                <span className="action_text">Изменить</span>
            </Button>
            <Popconfirm
                title="Маьлумот ўчирилади!"
                visible={visible}
                onConfirm={handleDelete}
                okButtonProps={{loading: loading}}
                onCancel={() => setVisible(false)}>
                <Button icon
                        loading={loading}
                        className="action_btn delete-btn"
                        onClick={() => {
                            if (rowId2) {
                                setVisible(true);
                            } else message.warning("Qatorni tanlang!");
                        }}>
                    <RiCloseLine className="action_icon"/>
                    <span className="action_text">Удалить</span>
                </Button>
            </Popconfirm>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        rowId: state.app.rowId,
        rowId2: state.app.rowId2,
        data: state.app.mainTableData,
        innerData: state.app.innerData,
        loading: state.app.loading,
        values: state.app.values3,
        allData: state.app.allData,
        innerDataHead: state.app.innerDataHead,
        innerDataType: state.app.innerDataType,
        currentPage: state.app.currentPage,
    };
};

export default connect(mapStateToProps, {
    setInnerModal2,
    setSmallModal,
    getMainTableData,
    setInnerData,
    getInnerData,
    setValues3,
    setRowId,
    getAllData,
    setRowId2,
    getInnerHeadData,
    setCurrentPage,
})(NewHeadActions);
