import React from "react";
import ServicesPage from "./Pages/ServicesPage";
import SimplePage from "./Pages/SimplePage";
import {SERVICES, SIMPLE, SKLAD_CHILD, SPRAVOCHNIK} from "./PageTypes";
import Spravochnik from "../../Pages/Spravochnik";
import SkladPage from "./Pages/SkladPage";

const Page = ({type, dataSource}) => {
    let page = "";
    switch (type) {
        case SIMPLE:
            page = <SimplePage searchDataSource={dataSource}/>;
            break;
        case SERVICES:
            page = <ServicesPage/>;
            break;
        case SPRAVOCHNIK:
            page = <Spravochnik/>;
            break;
        case SKLAD_CHILD:
            page = <SkladPage searchDataSource={dataSource}/>;
            break;
        default:
            break;
    }
    return page;
};

export default Page;
