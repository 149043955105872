import React from "react";
import "./Pages.css";
import ServiceItem from "./ServiceItem";
import servicePages from "./ServicePages/index";

const Service = () => {
    const pages = servicePages();
    return (
        <div>
            <h1 style={{textAlign: "center", fontWeight: "bold", fontSize: "30px"}}>
                Сервис
            </h1>
            <div className="service_grid">
                {
                    pages.map(d => (
                        <ServiceItem obj={d}/>
                    ))
                }
            </div>
        </div>
    );
};

export default Service;
