import {
    IMAGE,
    MODALTABLE, NUMBER,
    SELECT,
    STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {RiVipCrownFill} from "react-icons/ri";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";
import {BaseUrl} from "../../BaseUrl";
import Zoom from "react-medium-image-zoom";

const Mebel = {
    title: "Мебел",
    path: "/spravochnik/mebeli",
    icon: <RiVipCrownFill/>,
    type: SIMPLE,
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Номи</div>,
            dataIndex: "nomi",
            key: "nomi",
            width: "132px",
        },
        {
            title: <div className="table_head">Цена</div>,
            dataIndex: "narxi",
            key: "narxi",
            width: "132px",
        },
        {
            title: <div className="table_head">Модел</div>,
            dataIndex: "model",
            key: "model",
            width: "132px",
        },
    ],
    allData: [
        {value: "model", prop: "modeli", get: "nomi"},
    ],
    modalWidth: "900px",
    forms: [
        {
            grid: "1fr 1fr 1fr",
            inputs: [
                {
                    label: "Номи",
                    name: "nomi",
                    type: STRING,
                    required: true,
                    autoGetFocus: true,
                },
                {
                    label: "Цена",
                    name: "narxi",
                    type: NUMBER,
                    required: true,
                },
                {
                    label: "Модел",
                    name: "model",
                    type: SELECT,
                    required: true,
                    allData: {
                        key: "nomi",
                        type: "modeli",
                        text: "nomi"
                    }
                },
            ],
        },
    ],

};
export default Mebel;
