import React from "react";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import SecondTable from "../SecondTable";
import ModalTableActions from "./ModalTableActions";
import SkladHeaderBody from "../../SkladHeader/PostupleniyaHead/SkladHeaderBody";
import SkladHeaderCap from "../../SkladHeader/PostupleniyaHead/SkladHeaderCap";
import ZborkaHeaderBody from "../../SkladHeader/ZborkaHead/ZborkaHeaderBody";

const ModalTable = ({
                        columns,
                        document,
                        innerTable,
                        clearModal,
                        innerTableData,
                        innerDataHead,
                        currentPage,
                        stateDetector,
                    }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (innerTable && innerTableData) {
            setData([...innerTableData]);
        } else {
            setData([...document]);
        }
    }, [stateDetector, innerTableData, document, innerDataHead]);

    return (
        <div>
            {
                innerTable ? null : currentPage.path === "/postupleniya" ?
                    <>
                        <SkladHeaderCap/>
                        <SkladHeaderBody/>
                        <ModalTableActions/>
                    </>
                    :
                    <>
                        <SkladHeaderCap/>
                        <ZborkaHeaderBody/>
                        <ModalTableActions/>
                    </>
            }
            <SecondTable
                innerTable={innerTable}
                costumColumns={columns}
                id={innerTable ? "second-table" : ""}
                clearModal={clearModal}
                data={data}
                notPag={true}
            />
        </div>
    );
};
const mapStateToProps = state => {
    return {
        document: state.app.values.document ? state.app.values.document : [],
        innerModal: state.app.innerModal,
        innerDataHead: state.app.innerDataHead,
        stateDetector: state.app.stateDetector,
        currentPage: state.app.currentPage,
    };
};
export default connect(mapStateToProps)(ModalTable);
