import React from "react";
import {connect} from "react-redux";
import {setSmallModal, setValues2} from "../../../Actions/AppActions";
import "../Modal.css";
import Modal from "antd/lib/modal/Modal";
import {RiCloseCircleFill} from "react-icons/ri";
import NewHeadForm from "../../Actions/newHeadActions/NewHeadForm";

function NewHeadSmallModal({
                               setSmallModal,
                               innerSmallModal,
                               setData,
                               innerDataType,
                           }) {

    const clearModal = () => {
        setSmallModal(false)
    };

    return (
        <Modal
            centered
            maskClosable={false}
            keyboard={false}
            title={
                <div className="modal-title">
                    <span>{innerDataType.modalTitle}</span>
                </div>
            }
            width="850px"
            onCancel={clearModal}
            visible={innerSmallModal}
            footer={null}
            closeIcon={<RiCloseCircleFill/>}>
            <NewHeadForm clearModal={clearModal} setData={setData}/>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        innerSmallModal: state.app.innerSmallModal,
        currentPage: state.app.currentPage,
        innerDataType: state.app.innerDataType,

    };
};
export default connect(mapStateToProps, {
    setSmallModal,
    setValues2,
})(NewHeadSmallModal);
