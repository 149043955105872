import {
  PHONE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiScales2Fill } from "react-icons/ri";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const OlchovBirligi = {
  title: "Ўлчов бирлиги",
  path: "/servis/olchovBirligi",
  icon: <RiScales2Fill></RiScales2Fill>,
  type: SIMPLE,
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Номи</div>,
      dataIndex: "nomi",
      key: "nomi",
    },
  ],
  forms: [
    {
      grid: "1fr",
      inputs: [
        {
          label: "Номи",
          name: "nomi",
          type: STRING,
          required: true,
        },
      ],
    },
  ],
};

export default OlchovBirligi;
