import {BaseUrl} from "../../BaseUrl";
import {RiUserShared2Fill} from "react-icons/ri";
import {
    BUTTON_MIJOZ,
    DATE,
    MIJOZ_OTKAZ,
    MODALTABLE,
    SELECT,
    TEXTBLOCK,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";
import DocumentClass from "../../Functions/DocumentClass";
import NumberFormat from "../../Functions/NumberFormat";
import MiqdorOstatka from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorOstatka";
import {pul} from "../../Functions/Constanta";
import moment from "moment";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import ShowAllBtn from "./Components/ShowAllBtn";
import MiqdorInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorInput";
import {FaExchangeAlt} from "react-icons/all";

const Peremesheniye = {
    title: "Перемешение",
    path: "/peremesheniye",
    type: SIMPLE,
    icon: <FaExchangeAlt/>,
    allData: [
        {value: "mijoz_id", prop: "mijozlar", get: "fio"},
        {value: "xodim_id", prop: "agentlar", get: "fio"},
        {value: "kurs", prop: "constanta", get: "kurs"},
        {value: "qoldiq", prop: "ostatka", get: "qoldiq"},
    ],
    innerData: "ostatka",
    modalJami: "mijoz-buyurtma",
    defaultValues: [
        {
            prop: "valyuta_turi",
            value: 2,
        },
    ],
    modalWidth: "1336px",
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Сана</div>,
            dataIndex: "time_create",
            key: "nomi",
            width: "178px",
            render: text => moment(text).format("HH:mm DD.MM.YYYY"),
        },
        {
            title: <div className="table_head">Мижоз</div>,
            dataIndex: "mijozlar",
            key: "nomi",
            width: "178px",
        },
        {
            title: <div className="table_head">Агент</div>,
            dataIndex: "agentlar",
            key: "nomi",
            width: "178px",
        },
        {
            title: <div className="table_head border-bottom">Қарзи</div>,
            children: [
                {
                    title: <div className="table_head">сўм</div>,
                    dataIndex: "qarzi_som",
                    key: "nomi",
                    width: "150px",
                    render: text => NumberFormat(text),
                },
                // {
                //   title: <div className="table_head">вал</div>,
                //   dataIndex: "qarzi_val",
                //   key: "nomi",
                //   width: "120px",
                //   render: text => NumberFormat(text),
                // },
            ],
        },
        // {
        //   title: <div className="table_head">Валюта тури</div>,
        //   dataIndex: "valyuta_turi",
        //   key: "blok_soni",
        //   width: "101px",
        //   render: text => (text == 1 ? "Доллар" : "Сўм"),
        // },

        {
            title: <div className="table_head border-bottom">Жами миқдор</div>,
            children: [
                {
                    title: <div className="table_head">блок</div>,
                    dataIndex: "document",
                    key: "nomi",
                    width: "178px",
                    render: text => DocumentClass(text, "sotuv-bolimi").miqdor_blok,
                },
                {
                    title: <div className="table_head">сони</div>,
                    dataIndex: "document",
                    key: "nomi",
                    width: "178px",
                    render: text => DocumentClass(text, "sotuv-bolimi").miqdor_soni,
                },
            ],
        },
        {
            title: <div className="table_head border-bottom">Жами</div>,
            children: [
                {
                    title: <div className="table_head">сумма сўм</div>,
                    dataIndex: "document",
                    key: "nomi",
                    width: "150px",
                    render: text =>
                        isNaN(DocumentClass(text, "sotuv-bolimi").sotish_summa)
                            ? 0
                            : NumberFormat(DocumentClass(text, "sotuv-bolimi").sotish_summa),
                },
                // {
                //   title: <div className="table_head">сумма вал</div>,
                //   dataIndex: "document",
                //   key: "nomi",
                //   width: "150px",
                //   render: text =>
                //     isNaN(DocumentClass(text, "sotuv-bolimi").sotish_val_summa)
                //       ? 0
                //       : NumberFormat(
                //           DocumentClass(text, "sotuv-bolimi").sotish_val_summa,
                //           1
                //         ),
                // },
            ],
        },
        {
            title: <div className="table_head">Изох</div>,
            dataIndex: "izoh",
            key: "nomi",
            width: "178px",
        },
    ],
    forms: [
        {
            type: "document",
            grid: "1fr",
            actionInputs: [
                {
                    grid: "1fr 1fr 1fr 1fr 1fr",
                    inputs: [
                        {
                            label: "Сана",
                            name: "time_create",
                            type: DATE,
                        },
                        {
                            label: "Мижозлар",
                            name: "mijoz_id",
                            type: SELECT,
                            required: true,
                            allData: {
                                type: "mijozlar",
                                key: "Id",
                                text: "fio",
                            },
                        },
                        {
                            label: "Агентлар",
                            name: "agent_id",
                            type: SELECT,
                            required: true,
                            allData: {
                                type: "agentlar",
                                key: "Id",
                                text: "fio",
                            },
                        },
                        {
                            label: "Қарзи сўм",
                            name: "qarzi_som",
                            type: TEXTBLOCK,
                        },
                        // {
                        //   label: "Қарзи валюта",
                        //   name: "qarzi_val",
                        //   type: TEXTBLOCK,
                        // },

                        {
                            label:
                                "........",
                            name: "Буюртмани қабул қилиш",
                            type: BUTTON_MIJOZ,
                        },
                        {
                            label:
                                ".........................................................................",
                            name: "Буюртмани бекор қилиш",
                            type: MIJOZ_OTKAZ,
                        },
                        // {
                        //   label: "Валюта тури",
                        //   name: "valyuta_turi",
                        //   required: true,
                        //   type: SELECT,
                        //   costumData: [
                        //     { Id: 1, nomi: "Валюта" },
                        //     { Id: 2, nomi: "Сўм" },
                        //   ],
                        // },
                    ],
                },
            ],
            inputs: [
                {
                    name: "document",
                    type: MODALTABLE,
                    innerModal: {
                        title: "Маҳсулотлар",
                        columns: [
                            {
                                title: <div className="table_head">№</div>,
                                key: "index",
                                render: (text, record, index) => index + 1,
                                width: "50px",
                                align: "center",
                            },
                            {
                                title: <div className="table_head">Маҳсулот</div>,
                                dataIndex: "nomi",
                                key: "nomi",
                                width: "300px",
                            },
                            // {
                            //   title: <div className="table_head">Валюта тури</div>,
                            //   dataIndex: "valyuta_turi",
                            //   key: "blok_soni",
                            //   width: "101px",
                            //   render: text => (text == 1 ? pul.valyuta : pul.som),
                            // },
                            // {
                            //   title: <div className="table_head">Сотиш нархи вал</div>,
                            //   dataIndex: "sotish_narxi",
                            //   width: "101px",
                            //   render: (text, record) => (record.valyuta_turi == 1 ? text : 0),
                            // },

                            {
                                title: <div className="table_head">Сотиш нархи сўм</div>,
                                dataIndex: "sotish_narxi",
                                width: "101px",
                                render: (text, record) =>
                                    record.valyuta_turi == 2
                                        ? NumberFormat(text)
                                        : NumberFormat(record.sotish_som ? record.sotish_som : 0),
                            },
                            {
                                title: <div className="table_head">Блок сони</div>,
                                dataIndex: "blok_soni",
                                key: "blok_soni",
                                width: "100px",
                            },
                            {
                                title: <div className="table_head">Қолдиқ</div>,
                                dataIndex: "qoldiq",
                                key: "nomi",
                                width: "100px",
                            },
                            {
                                title: <div className="table_head">Расми</div>,
                                dataIndex: "img",
                                key: "img",
                                render: text => {
                                    if (text) {
                                        return (
                                            <picture>
                                                <source
                                                    media="max-width: 200px"
                                                    srcSet={BaseUrl + text.slice(1)}
                                                />
                                                <img
                                                    src={BaseUrl + text.slice(1)}
                                                    alt="asda"
                                                    height={30}
                                                />
                                            </picture>
                                        );
                                    } else return "";
                                },
                            },
                        ],
                    },

                    costumColumns: [
                        {
                            title: <div className="table_head">№</div>,
                            key: "index",
                            render: (text, record, index) => index + 1,
                            width: "65px",
                            align: "center",
                        },
                        {
                            title: <div className="table_head">Маҳсулот</div>,
                            dataIndex: "element_id",
                            key: "nomi",
                            width: "200px",
                            render: text => (
                                <div style={{display: "flex", alignItems: "flex-end"}}>
                                    <MiqdorSelect
                                        name={"element_id"}
                                        value={text}
                                        placeholder="Махсулот"
                                        get="nomi"
                                        innerDataType={"ostatka"}
                                        document={true}
                                    ></MiqdorSelect>
                                    <ShowAllBtn></ShowAllBtn>
                                </div>
                            ),
                        },
                        // {
                        //   title: <div className="table_head">Блок сони</div>,
                        //   dataIndex: "blok_soni",
                        //   key: "nomi",
                        //   width: "100px",
                        // },
                        // {
                        //   title: <div className="table_head">Нарх тури</div>,
                        //   dataIndex: "valyuta_turi",
                        //   key: "valyuta_turi",
                        //   width: "100px",
                        //   render: text => (text === 1 ? pul.valyuta : pul.som),
                        // },
                        {
                            title: <div className="table_head">Қолдиқ</div>,
                            dataIndex: "qoldiq",
                            key: "nomi",
                            width: "100px",
                        },
                        {
                            title: <div className="table_head">Блок сони</div>,
                            dataIndex: "miqdor_blok",
                            key: "nomi",
                            width: "130px",
                        },
                        {
                            title: <div className="table_head">Буюртма сони</div>,
                            dataIndex: "miqdor_soni",
                            key: "nomi",
                            width: "130px",
                        },
                        {
                            title: <div className="table_head">Сотиш сони</div>,
                            dataIndex: "sotish_soni",
                            key: "nomi",
                            width: "130px",
                            render: (text, record) => (
                                <MiqdorOstatka
                                    name="sotish_soni"
                                    type="name"
                                    miqdor={record.miqdor_soni}
                                    qoldiq={record.qoldiq}
                                    blok_soni={record.blok_soni}
                                    value={text}
                                ></MiqdorOstatka>
                            ),
                        },
                        {
                            title: <div className="table_head">Нархи</div>,
                            children: [
                                // {
                                //   title: <div className="table_head">вал</div>,
                                //   dataIndex: "sotish_narxi",
                                //   key: "nomi",
                                //   width: "120px",
                                //   render: (text, record) =>
                                //     record.valyuta_turi == 1 ? text : 0,
                                // },
                                {
                                    title: <div className="table_head">сўм</div>,
                                    dataIndex: "sotish_narxi",
                                    key: "nomi",
                                    width: "170px",
                                    render: text => (
                                        <MiqdorInput name="sotish_narxi" value={text}></MiqdorInput>
                                    ),
                                },
                            ],
                        },
                        {
                            title: <div className="table_head">Сумма</div>,
                            children: [
                                // {
                                //   title: <div className="table_head">вал</div>,
                                //   dataIndex: "sotish_narxi",
                                //   align: "right",
                                //   key: "nomi",
                                //   width: "120px",
                                //   render: (text, record) => {
                                //     if (record.valyuta_turi == 1) {
                                //       return isNaN(text * record.miqdor_soni)
                                //         ? 0
                                //         : NumberFormat(text * record.miqdor_soni, 1);
                                //     } else return null;
                                //   },
                                // },
                                {
                                    title: <div className="table_head">Сумма сўм</div>,
                                    dataIndex: "sotish_som",
                                    align: "right",
                                    key: "nomi",
                                    width: "170px",
                                    render: (text, record) => {
                                        if (text) {
                                            return isNaN(text * record.miqdor_soni)
                                                ? 0
                                                : NumberFormat(text * record.miqdor_soni);
                                        } else return null;
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

export default Peremesheniye;
