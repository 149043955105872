import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {
    changeDocumentObj,
    setDocument,
    stateDetector,
} from "../../../../Actions/AppActions";
import {Select} from "antd";

const {Option} = Select;
const SearchSelect = ({
                          setDocument,
                          name,
                          value,
                          options,
                          placeholder,
                          allData,
                          allDataType,
                          values,
                          get,
                          filterByModel,
                          innerData,
                          innerDataType,
                          document,
                          changeDocumentObj,
                          rowId,
                          stateDetector,
                          currentPage,
                          cursor,
                      }) => {
    function onSearch() {
    }

    let a = [];
    useEffect(() => {
        setTimeout(() => {
            if (cursor === 1) {
                selectRef && selectRef.current && selectRef.current.focus();
            }
        }, 20);

    }, []);
    const selectRef = useRef(null);

    const filterModel = (data) => {
        let a = [];
        if (data) {
            data.map(item => {
                allData && allData["modeli"].map(el => {
                    if (el.Id === values.model) {
                        if (item.model === el.nomi) {
                            a.push(
                                <Option value={item.Id} key={item.Id}>
                                    {item.nomi}
                                </Option>
                            );
                        }
                    }
                })
            })
        }
        return a;
    }

    const filterItem = (item) => {

        allData && allData["modeli"].map(el => {
            if (el.Id === values.model) {
                if (item.model === el.nomi) {
                    return item.nomi;
                }
            }
        })
    }

    return (
        <Select
            value={isNaN(value) ? "" : Number(value)}
            style={{width: 120}}
            showSearch
            ref={cursor === 1 ? selectRef : null}
            onSearch={onSearch}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={placeholder}
            onChange={v => {
                let a = {
                    target: {
                        name: name,
                        value: v,
                    },
                };
                if (document) {
                    let obj1 = {};
                    const one = innerData[innerDataType].find(d => d.Id == v) ? {
                            ...innerData[innerDataType].find(d => d.Id == v),
                            Id: rowId,
                            element_id: innerData[innerDataType].find(d => d.Id == v).Id,
                        }
                        : {};
                    changeDocumentObj({...one, ...obj1}, rowId);
                    stateDetector();
                } else {
                    setDocument(a);
                }
            }}>
            {
                allDataType ? allData[allDataType]?.map(d => (
                        <Option value={d.Id} key={d.Id}>
                            {d[get]}
                        </Option>
                    ))
                    : null}
            {
                options && options.map(op => (
                    <Option value={op.value} key={op.value}>
                        {op.text}
                    </Option>
                ))}
            {
                values.model ?
                    filterModel(innerData[innerDataType])
                    :
                    innerDataType && innerData[innerDataType]?.map(item =>
                        <Option value={item.Id} key={item.Id}>
                            {item[get]}
                        </Option>
                    )
            }
        </Select>
    );
};
const mapStateToProps = state => {
    return {
        allData: state.app.allData,
        innerData: state.app.innerData,
        values: state.app.values,
        rowId: state.app.rowId,
        currentPage: state.app.currentPage,
    };
};
export default connect(mapStateToProps, {
    setDocument,
    stateDetector,
    changeDocumentObj,
})(SearchSelect);
