import React from "react";
import {GrSearch} from "react-icons/gr";
import {SearchTableInput} from "ant-table-extensions";
import {connect} from "react-redux";
import Detal from "../../Pages/ServicePages/Detal";

const SearchCom = ({
                       dataSource,
                       newHeadData,
                       setS,
                       allData,
                       innerModal2,
                       values,
                       innerData,
                       innerDataType,
                       innerModal,
                       currentPage,
                   }) => {
    let data = [];
    let columns = [];

    if (innerModal2) {
        // columns = currentPage.header.postavshiki.mainData.columns || [];
        data = dataSource
    } else if (currentPage.allData?.length > 0 && !innerModal) {
        columns = currentPage.columns ? currentPage.columns : [];
        data = dataSource.map(d => {
            let a = {};
            currentPage.allData.forEach(obj => {
                if (allData[obj.prop]?.find(k => k.Id == d[obj.value])) {
                    a[obj.prop] = allData[obj.prop]?.find(k => k.Id == d[obj.value])[obj.get];
                }
            });

            return {...d, ...a};
        });
    } else if (innerModal) {
        if (currentPage.forms?.find(f => f.type === "document")) {
            columns = currentPage.forms?.find(f => f.type === "document").inputs[0].innerModal.columns;
            data = innerData.detali;
            console.log(data)
        }
    } else {
        columns = currentPage.columns ? currentPage.columns : [];
        data = dataSource;
    }


    if (currentPage.innerAll) {
        data = data.map(d => {
            let a = {};
            currentPage.innerAll.forEach(obj => {
                if (allData[obj.prop]?.find(k => k.Id == d[obj.value])) {
                    a[obj.prop] = allData[obj.prop]?.find(k => k.Id == d[obj.value])[obj.get];
                }
            });

            return {...d, ...a};
        })
    }


    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifySelf: "flex-start",
        }}>&nbsp;
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "15px",
                }}>
                <SearchTableInput
                    columns={columns}
                    dataSource={newHeadData || data} // 🔴 Original dataSource
                    setDataSource={setS} // 🔴 Newly created setSearchDataSource from useState hook
                    inputProps={{
                        placeholder: "Қидириш",
                        prefix: <GrSearch/>,
                    }}
                    fuzzySearch={true}
                    fuseProps={{
                        keys: columns.map(d => d.dataIndex).filter(a => a),
                        threshold: 0.2,
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        values: state.app.secondaryModal.open
            ? state.app.values2
            : state.app.values,
        dataSource: state.app.mainTableData,
        columns: state.app.currentPage.columns ? state.app.currentPage.columns : [],
        currentPage: state.app.currentPage,
        innerData: state.app.innerData,
        innerDataType: state.app.innerDataType,
        allData: state.app.allData,
        innerModal: state.app.innerModal,
        innerModal2: state.app.innerModal2,
    };
};
export default connect(mapStateToProps)(SearchCom);
