import {Button} from "antd";
import React from "react";
import {connect} from "react-redux";
import {setInnerModal} from "../../../Actions/AppActions";

const ShowAllBtn = ({setInnerModal}) => {
    return <Button onClick={() => setInnerModal(true)}>...</Button>;
};

export default connect(null, {setInnerModal})(ShowAllBtn);
