import {Button} from "antd";
import React from "react";
import {connect} from "react-redux";
import {
    setInnerDataType2, setInnerModal2
} from "../../../Actions/AppActions";

const OpenAllBtn = ({setInnerModal2, setInnerDataType2, currentPage}) => {

    const openNewHeadModal = (head) => {
        setInnerModal2(true);
        setInnerDataType2(head);
    }
    return <Button onClick={() => openNewHeadModal(currentPage.header.detali)}>...</Button>
};


const mapStateToProps = state => {
    return {
        currentPage: state.app.currentPage
    };
};
export default connect(mapStateToProps, {
    setInnerModal2,
    setInnerDataType2,
})(OpenAllBtn);