import {Button, message} from "antd";
import React, {useEffect} from "react";
import {RiAddCircleFill, RiDeleteBinFill} from "react-icons/ri";
import {connect} from "react-redux";
import uuid from "react-uuid";
import scrollIntoView from "scroll-into-view";
import {
    addDocument,
    deleteDocument,
    setRowId,
    stateDetector,
} from "../../../Actions/AppActions";
import ModalInputs from "../../Modals/ModalInputs";
import TextArea from "antd/es/input/TextArea";

const ModalTableActions = ({
                               rowId,
                               deleteDocument,
                               addDocument,
                               currentPage,
                               stateDetector,
                               setRowId,
                               modal,
                           }) => {
    useEffect(() => {
        document.addEventListener("keydown", KeyboardEvents);

        return () => {
            document.removeEventListener("keydown", KeyboardEvents);
        };
    }, [rowId, modal]);

    const KeyboardEvents = event => {
        if (modal) {
            switch (event.key) {
                case "Insert":
                    openAdd();
                    break;
                case "Delete":
                    handleDelete(rowId);
                    break;
                default:
                    break;
            }
        }
    };
    const openAdd = () => {
        const Id = uuid();
        addDocument({Id});
        setRowId(Id);
        stateDetector();
        scrollIntoView(document.querySelector(".scroll-row"), {
            align: {
                top: 0,
            },
        });
    };
    const handleDelete = id => {
        // console.log(rowId);
        if (id) {
            deleteDocument(rowId);
            stateDetector();
        } else {
            message.error("Qatorni tanlang!");
        }
    };
    let a = [];
    if (currentPage.forms.length > 0 && currentPage.forms[0].type === "document") {
        a = currentPage.forms[0].actionInputs;
    }
    return (
        <div style={{display: "flex", gridGap: "10px", alignItems: "flex-end"}}>
            <Button onClick={openAdd} type="primary" className="action_btn main-btn">
                <RiAddCircleFill style={{fontSize: "18px"}}/>
            </Button>
            <Button onClick={() => handleDelete(rowId)} className="action_btn delete-btn">
                <RiDeleteBinFill style={{fontSize: "18px"}}/>
            </Button>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        rowId: state.app.rowId,
        currentPage: state.app.currentPage,
        modal: state.app.modal,
    };
};
export default connect(mapStateToProps, {
    deleteDocument,
    addDocument,
    stateDetector,
    setRowId,
})(ModalTableActions);
