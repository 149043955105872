import React from "react";
import "../skladHeader.scss";
import "moment/locale/ru";
import {connect} from "react-redux";
import {
    setValues
} from "../../../Actions/AppActions";
import ruRu from "antd/lib/locale/ru_RU";
import makeField from "../components/makeField";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";
import {DatePicker, Input} from "antd";
import TextArea from "antd/es/input/TextArea";

const SkladHeaderCap = ({setValues, values}) => {

    const changeDatePicker = (e) => {
        setValues({...values, sana: e.target.value})
    }
    const mainData = [
        {
            type: "HEAD_INPUT",
            name: "documentId",
            value: values && values.unikalId,
            readOnly: true,
            placeholder: "номер"
        },
        {
            type: "HEAD_DATE",
            name: "skladHeadDate",
            readOnly: true,
            placeholder: "номер"
        },
    ];

    const changeComment = (e) => {
        setValues({...values, izoh: e.target.value})
    }

    return (
        <>
            <div className="sklad-head-cap">
                {/*{*/}
                {/*    mainData && mainData.map(field => {*/}
                {/*        makeField(field);*/}
                {/*    })*/}
                {/*}*/}
                <div className="sklad-head-cap__item">
                    <Input
                        name="documentId"
                        className="document-id-input"
                        value={values && values.unikalId}
                        readOnly={true}
                        placeholder="номер"/>
                    <div>

                        <span className="cap-text">от</span>
                        <DatePicker
                            showTime
                            // values={values["sana"] || moment(new Date(), 'YYYY-MM-DD')}
                            defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                            format="DD.MM.YYYY HH:mm"
                            allowClear={false}
                            locale={locale}
                            onChange={v => {
                                const e = {
                                    target: {
                                        name: "skladHeadDate",
                                        value: moment(v).format("YYYY-MM-DD HH:mm:ss"),
                                    },
                                };
                                changeDatePicker(e)
                            }}
                        />
                    </div>
                </div>
                <div style={{display: "flex", minWidth: "230px"}}>
                    <span className="cap-text">Комментария</span>
                    <TextArea value={values["izoh"] || null}
                              rows={1}
                              onChange={changeComment}
                              className="sklad-head-body__comment"/>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = state => {
    return {
        values: state.app.secondaryModal.open
            ? state.app.values2
            : state.app.values,
    };
};

export default connect(mapStateToProps, {
    setValues,
})(SkladHeaderCap);
