import React, {useEffect, useRef} from "react";
import {
    RiAddLine,
    RiCloseLine,
    RiPencilLine,
} from "react-icons/ri";
import {Button, message, Popconfirm} from "antd";
import {useState} from "react";
import "./SecondActions.scss";
import {
    setModal,
    setInnerData,
    getMainTableData,
    setValues,
    setRowId,
    setCurrentPage, setSmallModal, setValues3, getInnerData, setRowId2,
} from "../../../Actions/AppActions";
import {connect} from "react-redux";
import fetchApi from "../../../Functions/fetchApi";
import {useHistory} from "react-router-dom";
import FetchApi from "../../../Functions/fetchApi";
import moment from "moment";

const SecondActions = ({
                           setModal,
                           rowId,
                           data,
                           innerData,
                           values,
                           setInnerData,
                           setValues3,
                           setValues,
                           setSmallModal,
                           setRowId2,
                           getInnerData,
                           currentPage,
                           getMainTableData,
                           setColumns,
                           rowId2,
                           allData,
                           setRowId,
                       }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        document.addEventListener("keydown", KeyboardEventss);

        return () => {
            document.removeEventListener("keydown", KeyboardEventss);
        };
    }, []);

    const KeyboardEventss = event => {
        switch (event.key) {
            case "Insert":
                // openAdd();
                break;

            default:
                break;
        }
    };
    const RequestJson = async (url, data) => {
        let user_id = null;
        if (JSON.parse(window.localStorage.getItem("user")) &&
            JSON.parse(window.localStorage.getItem("user")).Id) {
            user_id = JSON.parse(window.localStorage.getItem("user")).Id;
        }
        // setLoadings(true);
        const response = await FetchApi(url, "GET", {...data, user_id: user_id});
        if (response && response.status === 200) {
            let obj = {};
            switch (currentPage.path) {
                case "/postupleniya":
                    obj = {
                        ...values,
                        unikalId: response.data[0] && +response.data[0].unikalId + 1 || 100000001,
                        sana: values.sana ? values.sana : moment(new Date(), 'YYYY-MM-DD'),
                        postavshik_id: "",
                        jami: "",
                        sklad: "",
                        izoh: "",
                        document: []
                    }
                    break;
                case "/zborka":
                    obj = {
                        ...values,
                        unikalId: response.data[0] && +response.data[0].unikalId + 1 || 100000001,
                        sana: values.sana ? values.sana : moment(new Date(), 'YYYY-MM-DD'),
                        jami: "",
                        mebel: "",
                        model: "",
                        sklad: "",
                        izoh: "",
                        document: []
                    }
                    break;
                case "/spravochnik/detali":
                    obj = {
                        ...values,
                        barcode: "" + (response.data[0] && +response.data[0].barcode + 1 || 250000000000),
                    }
                    break;
            }
            setValues(obj)
        }
        // setLoadings(false);
    };
    const openAdd = async () => {
        // setRowId(null);
        setRowId2(null);
        // setValues3({});
        if (currentPage.defaultValues && currentPage.defaultValues.length > 0) {
            let obj = {};
            currentPage.defaultValues.forEach(d => {

                obj[d.prop] = d.value;
            });
            setValues(obj);
        }
        setSmallModal(true);
    };

    const {location} = useHistory();

    const openEdit = () => {
        if (rowId2) {
            let obj = innerData && innerData.detali.find(d => d.Id === rowId2);
            // console.log(rowId2)
            // console.log(obj)
            setValues3(obj);
            setSmallModal(true);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };
    const handleDelete = async () => {
        let user_id = null;
        if (
            JSON.parse(window.localStorage.getItem("user")) &&
            JSON.parse(window.localStorage.getItem("user")).id
        ) {
            user_id = JSON.parse(window.localStorage.getItem("user")).id;
        }

        if (rowId2) {
            console.log("rowId2")
            setLoading(true);
            const res = await fetchApi("/spravochnik/detali/" + rowId2, "DELETE", {
                user_id: user_id,
            });
            if (res && res.status === 200) {
                setRowId2(null);
                const obj = currentPage.forms?.find(f => f.type === "document").inputs[0].innerModal;
                setColumns(obj.columns);
                getInnerData([currentPage.innerData]);
            }
            setVisible(false);
            setLoading(false);
        } else if (rowId) {
            setLoading(true);
            const res = await fetchApi("/spravochnik/detali/" + rowId, "DELETE", {
                user_id: user_id,
            });
            if (res && res.status === 200) {
                setRowId(null);
                // getMainTableData("/postupleniya/detali");
            }
            setVisible(false);
            setLoading(false);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };

    useEffect(() => {
        if (values.valyuta_turi && innerData.ostatka) {
            if (values.valyuta_turi == 1) {
                setInnerData({
                    ostatka: innerData.ostatka.filter(d => d.valyuta_turi == 1),
                });
            } else {
                const doc = [...innerData.ostatka].map(d => {
                    if (d.valyuta_turi == 1) {
                        return {
                            ...d,
                            kirim_som: d.kirim_narxi * allData.constanta[0]?.kurs,
                            sotish_som: d.sotish_narxi * allData.constanta[0]?.kurs,
                        };
                    } else return d;
                });
                setInnerData({
                    ostatka: doc,
                });
            }
        }
    }, [loading, setLoading]);
    // useEffect(() => {
    //     if (currentPage.innerData) {
    //         // Model ga qarab filtrlab beradi
    //         if (innerData[currentPage.innerData]) {
    //             if (currentPage.path === "/zborka") {
    //                 let detal = "";
    //                 allData && allData.modeli.map(item => {
    //                     if (item.Id === values.model) {
    //                         detal = item;
    //                     }
    //                 })
    //                 let result = [];-
    //                 innerData[currentPage.innerData].map(item => {
    //                     if (item.model === detal.nomi) {
    //                         result.push(item)
    //                     }
    //                 })
    //                 setSearchDataSource(result);
    //             } else {
    //                 setSearchDataSource(innerData[currentPage.innerData]);
    //             }
    //         }
    //     }
    // }, [innerData, setInnerData]);

    useEffect(() => {
        if (currentPage.innerData) {
            getInnerData([currentPage.innerData]);
        }
    }, [setModal]);
    return (
        <div className="second-actions">
            <Button onClick={openAdd} type="primary" className="action_btn main-btn">
                <RiAddLine className="action_icon"/>
                <span className="action_text">Добавить</span>
            </Button>
            <Button onClick={openEdit} type="primary" className="action_btn edit-btn">
                <RiPencilLine className="action_icon"/>
                <span className="action_text">Изменить</span>
            </Button>
            <Popconfirm
                title="Маьлумот ўчирилади!"
                visible={visible}
                onConfirm={handleDelete}
                okButtonProps={{loading: loading}}
                onCancel={() => setVisible(false)}>
                <Button icon
                        loading={loading}
                        className="action_btn delete-btn"
                        onClick={() => {
                            if (rowId) {
                                setVisible(true);
                            } else message.warning("Qatorni tanlang!");
                        }}>
                    <RiCloseLine className="action_icon"/>
                    <span className="action_text">Удалить</span>
                </Button>
            </Popconfirm>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        rowId: state.app.rowId,
        rowId2: state.app.rowId2,
        data: state.app.mainTableData,
        innerData: state.app.innerData,
        loading: state.app.loading,
        values: state.app.values3,
        allData: state.app.allData,
        currentPage: state.app.currentPage,
    };
};

export default connect(mapStateToProps, {
    setModal,
    setSmallModal,
    getMainTableData,
    setInnerData,
    getInnerData,
    setValues3,
    setRowId,
    setRowId2,
    setCurrentPage,
})(SecondActions);
