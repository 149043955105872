import {BaseUrl} from "../../BaseUrl";
import {
    BARCODE_INPUT,
    COSTUM,
    DATE, IMAGE,
    MODALTABLE, SELECT, STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {SKLAD_CHILD} from "../../Component/Sidebar/PageTypes";
import moment from "moment";
import {FaExchangeAlt,} from "react-icons/all";
import Zoom from "react-medium-image-zoom";
import MiqdorInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorInput";
import Barcode from "react-barcode";
import Postavshiki from "../ServicePages/Postavshiki";
import Sklad from "../ServicePages/Components/Sklad";
import Detal from "../ServicePages/Detal";
import React from "react";
import OpenAllBtn from "./Components/OpenAllBtn";
import SearchSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";

const Postupleniya = {
    title: "Поступления",
    path: "/postupleniya",
    type: SKLAD_CHILD,
    icon: <FaExchangeAlt/>,
    allData: [
        {value: "detal", prop: "detali", get: "nomi"},
        {value: "model_id", prop: "modeli", get: "nomi"},
        {value: "sklad", prop: "skladi", get: "nomi"},
        {value: "postavshik", prop: "postavshiki", get: "fullName"},
        // {value: "postavshik", prop: "postavshiki", get: "fullName"},
    ],
    innerData: "detali",
    modalJami: "postupleniya-foot",
    modalWidth: "1000px",
    hisobotActions: [
        {
            grid: "1fr 1fr 1fr 1fr 1fr 1fr",
            inputs: [
                {
                    label: "Сана",
                    name: "sana",
                    type: DATE,
                },
                {
                    type: COSTUM,
                    component: (
                        <div style={{display: "flex", gridGap: "10px", alignItems: "center"}}/>
                    ),
                },
            ],
        },
    ],
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Документ</div>,
            dataIndex: "unikalId",
            key: "nomi",
            width: "100px",
        },
        {
            title: <div className="table_head">Сана</div>,
            dataIndex: "time_create",
            key: "nomi",
            width: "178px",
            render: text => moment(text).format("HH:mm DD.MM.YYYY"),
        },
        {
            title: <div className="table_head">Поставщик</div>,
            dataIndex: "postavshiki",
            key: "nomi",
            width: "178px",
        },
        {
            title: <div className="table_head">Склад</div>,
            dataIndex: "skladi",
            key: "nomi",
            width: "178px",
        },
        {
            title: <div className="table_head">Коммент</div>,
            dataIndex: "izoh",
            key: "nomi",
            width: "178px",
        },
        {
            title: <div className="table_head">Всего</div>,
            dataIndex: "jami",
            key: "nomi",
            width: "178px",
        },
    ],
    forms: [
        {
            type: "document",
            grid: "1fr",
            actionInputs: [
                {
                    grid: "1fr 1fr 1fr 1fr 1fr 1fr",
                    name: "actionInputs",
                    inputs: [
                        {
                            label: "Сана",
                            name: "sana",
                            type: DATE,
                            required: true,
                        },
                        {
                            label: "Хужжат номери",
                            name: "xujjat_nomeri",
                            type: STRING,
                            required: true,
                        },
                    ],
                },
            ],
            inputs: [
                {
                    name: "document",
                    type: MODALTABLE,
                    innerModal: {
                        title: "Деталлар",
                        columns: [
                            {
                                title: <div className="table_head">№</div>,
                                key: "index",
                                render: (text, record, index) => index + 1,
                                width: "50px",
                                align: "center",
                            },
                            {
                                title: <div className="table_head">Номи</div>,
                                dataIndex: "nomi",
                                key: "nomi",
                                width: "auto",
                            },
                            {
                                title: <div className="table_head">Модел</div>,
                                dataIndex: "model",
                                key: "model",
                                width: "158px",
                            },
                            {
                                title: <div className="table_head">Штрих код</div>,
                                dataIndex: "barcode",
                                key: "barcode",
                                width: "140px",
                                align: "center",
                                render: (name) => (
                                    <Barcode width={"1px"}
                                             height={"40px"}
                                             margin={0}
                                             displayValue={false}
                                             value={name}/>
                                )
                            },
                            {
                                title: <div className="table_head">Фото</div>,
                                dataIndex: "img",
                                key: "img",
                                width: "100px",
                                render: text => {
                                    if (text) {
                                        return (
                                            <Zoom zoomMargin={40}>
                                                <picture>
                                                    <source
                                                        media="max-width: 200px"
                                                        srcSet={BaseUrl + text.slice(1)}
                                                    />
                                                    <img src={BaseUrl + text.slice(1)} alt="Детал расми" height={30}/>
                                                </picture>
                                            </Zoom>
                                        );
                                    } else return "";
                                },
                            },
                        ],
                    },
                    costumColumns: [
                        {
                            title: <div className="table_head">№</div>,
                            key: "index",
                            render: (text, record, index) => index + 1,
                            width: "70px",
                            align: "center",
                        },
                        {
                            title: <div className="table_head">Детал</div>,
                            dataIndex: "element_id",
                            key: "nomi",
                            width: "200px",
                            render: text => (
                                <div style={{display: "flex", alignItems: "flex-end"}}>
                                    <SearchSelect
                                        name={"element_id"}
                                        value={text}
                                        placeholder="Деталь"
                                        get="nomi"
                                        cursor="1"
                                        innerDataType={"detali"}
                                        document={true}
                                    />
                                    <OpenAllBtn/>
                                </div>
                            ),
                        },
                        {
                            title: <div className="table_head">Штрих</div>,
                            dataIndex: "barcode",
                            key: "barcode",
                            width: "100px",
                        },
                        {
                            title: <div className="table_head">Кол-во</div>,
                            dataIndex: "kolvo",
                            key: "kolvo",
                            width: "100px",
                            render: text => (
                                <MiqdorInput name="kolvo" value={text}/>
                            ),
                        },
                        {
                            title: <div className="table_head">Модел</div>,
                            dataIndex: "model",
                            key: "nomi",
                            width: "100px",
                        },
                        {
                            title: <div className="table_head">Фото</div>,
                            dataIndex: "img",
                            key: "img",
                            width: "70px",
                            render: text => {
                                if (text) {
                                    return (
                                        <Zoom zoomMargin={40}>
                                            <picture>
                                                <source media="max-width: 200px"
                                                        srcSet={BaseUrl + text.slice(1)}/>
                                                <img src={BaseUrl + text.slice(1)} alt="asda" height={30}/>
                                            </picture>
                                        </Zoom>
                                    );
                                } else return "";
                            },
                        },
                    ],
                },
            ],
        },
    ],
    header: {
        postavshiki: {
            modalTitle: "Поставшики",
            dataType: "postavshiki",
            mainData: Postavshiki
        },
        skladi: {
            modalTitle: "Склады",
            dataType: "skladi",
            mainData: Sklad
        },
        detali: {
            modalTitle: "Детали",
            dataType: "detali",
            mainData: Detal
        },
    },
    innerSmallModal: {
        title: "Детал",
        path: "/spravochnik/detali",
        forms: [
            {
                grid: "1fr 1fr",
                inputs: [
                    {
                        label: "Номи",
                        name: "nomi",
                        type: STRING,
                        required: true,
                    },
                    {
                        label: "Модел",
                        name: "model",
                        type: SELECT,
                        required: true,
                        allData: {
                            type: "modeli",
                            key: "nomi",
                            text: "nomi"
                        }
                    }
                ],
            },
            {
                grid: "1fr 1fr",
                inputs: [
                    {
                        label: "Штрих ID",
                        name: "barcode",
                        type: BARCODE_INPUT,
                        required: true,
                        readOnly: true,
                    },
                    {
                        label: "Расм",
                        name: "img",
                        type: IMAGE,
                    },
                ],
            },
        ],
    }
};

export default Postupleniya;
