import React from "react";
import "../skladHeader.scss";
import {Button, Select} from "antd";
import {Option} from "antd/lib/mentions";
import {connect} from "react-redux";
import {
    getAllData,
    getHisobot,
    getInnerData,
    setInnerData,
    setInnerDataType2,
    setInnerHeadData,
    setInnerModal,
    setInnerModal2,
    setValues,
} from "../../../Actions/AppActions";
import {postavshik} from "../components/constants";

const SkladHeaderBody = ({
                             getInnerData,
                             allData,
                             values,
                             setInnerModal2,
                             currentPage,
                             setInnerHeadData,
                             setInnerDataType2,
                             setValues,
                         }) => {

    const changeSklad = (e) => {
        setValues({...values, sklad: e.target.value})
    }

    const changePostavshik = (e) => {
        setValues({...values, postavshik: e.target.value})
    }

    const changeComment = (e) => {
        setValues({...values, izoh: e.target.value})
    }

    const openInnerModal = async (type) => {
        setInnerModal2(true);
        // console.log("allData.postavshiki");
        // console.log(currentPage.innerData);
        switch (type) {
            case postavshik:
                setInnerHeadData({
                    data: allData && allData.postavshiki
                })
                const obj = currentPage && currentPage.header;
                await getInnerData(["postavshiki"]);
                // setTitle(obj.modalTitle);
                // setColumns(obj.columns);
                if (allData[obj.dataType]) {
                    // await setSearchDataSource(allData[obj.dataType]);
                }
                break;
        }
    }

    const openNewHeadModal = (head) => {
        setInnerModal2(true);
        setInnerDataType2(head);
    }

    return (
        <>
            <div className="sklad-head-body">
                <div className="sklad-head-body__item">
                    <span className="cap-text">Поставшик:</span>
                    <div>

                        {/*{*/}
                        {/*    console.log("values")*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    console.log(values)*/}
                        {/*}*/}
                        <Select
                            placeholder="Поставшики"
                            className="head-select"
                            showSearch
                            value={values["postavshik"] || null}
                            required={true}
                            allowClear={true}
                            // loading={loading2}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={value => {
                                const v = {
                                    target: {
                                        name: "postavshik",
                                        value: value,
                                    },
                                };
                                changePostavshik(v)
                            }}>
                            {
                                allData && allData.postavshiki ? allData.postavshiki.map(d => (
                                    <Option key={d.Id} value={d.Id}>
                                        {d.fullName}
                                    </Option>
                                )) : ""
                            }
                        </Select>
                        <Button onClick={() => openNewHeadModal(currentPage.header.postavshiki)}>...</Button>
                    </div>
                </div>
                <div className="sklad-head-body__item">
                    <span className="cap-text">Склад:</span>
                    <div>
                        <Select
                            placeholder="Склады"
                            showSearch
                            className="head-select"
                            value={values["sklad"] || null}
                            required={true}
                            allowClear={true}
                            // loading={loading2}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={value => {
                                const v = {
                                    target: {
                                        name: "sklad",
                                        value: value,
                                    },
                                };
                                changeSklad(v)
                            }}>
                            {
                                allData && allData.skladi ? allData.skladi.map(d => (
                                    <Option key={d.Id} value={d.Id}>
                                        {d.nomi}
                                    </Option>
                                )) : ""
                            }
                        </Select>
                        <Button onClick={() => openNewHeadModal(currentPage.header.skladi)}>...</Button>
                    </div>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.app.allData,
        currentPage: state.app.currentPage,
        values: state.app.secondaryModal.open
            ? state.app.values2
            : state.app.values,
    };
};
export default connect(mapStateToProps, {
    getHisobot,
    getAllData,
    setInnerModal,
    setInnerModal2,
    setInnerDataType2,
    setValues,
    getInnerData,
    setInnerData,
    setInnerHeadData
})(SkladHeaderBody);
