import {Button, message} from "antd";
import moment from "moment";
import {useEffect} from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    changeAllDocument,
    getInnerData,
    getMainTableData,
    setLoading,
    setMainTableData,
    setValues,
} from "../../Actions/AppActions";
import FetchApi from "../../Functions/fetchApi";
import ModalInputs from "./ModalInputs";
import DocumentClass from "../../Functions/DocumentClass";

const ModalForm = ({
                       values,
                       clearModal,
                       setLoading,
                       modal,
                       getMainTableData,
                       currentPage,
                       setValues,
                       allData,
                       secondaryModal,
                       changeAllDocument,
                       rowId,
                       actualValues,
                       getInnerData,
                   }) => {

    useEffect(() => {
        if (modal && currentPage.innerData) {
            getInnerData([currentPage.innerData]);
        }
    }, [modal]);

    const {location} = useHistory();
    const history = useHistory();
    const [loadings, setLoadings] = useState(false);
    const key = 32432;
    const handleSubmit = () => {
        const requiredInputs = [];
        currentPage.forms.forEach(el => {
            el.inputs.forEach(d => {
                if (d.required) {
                    requiredInputs.push(d);
                }
            });
        });
        let error = [];
        requiredInputs.forEach(d => {
            if (!(values[d.name] && values[d.name] !== "")) {
                error.push(`${d.label} kiriting!`);
            }
        });
        if (error.length > 0) {
            message.error({content: error[0], key: key});
        } else {
            clearModal();
            console.log(values);
            RequestJson(
                secondaryModal.open ? secondaryModal.path : location.pathname,
                {...values, user_id: null},
                getMainTableData
            );
        }
    };

    const elementChangeDocument = () => {
        const document = actualValues.document;
        let newDocument = [];
        if (document) {
            newDocument = document.map(doc => {
                if (doc.Id == rowId) {
                    return {
                        ...doc,
                        ...values,
                        Id: rowId,
                        element_id: values.Id,
                    };
                } else return doc;
            });

            changeAllDocument(newDocument);
        }
    };

    const RequestJson = async (url, data, refreshCallback, closeModal) => {
        let user = JSON.parse(window.localStorage.getItem("user"));
        let user_id = user ? user.id : null;

        setLoadings(true);
        switch (location.pathname) {
            case "/postupleniya":
            case "/zborka":
                await setValues(
                    {
                        ...values,
                        jami: DocumentClass(values.document, "postupleniya-foot").jami_miqdor + "",
                    });
                break;
        }
        const response = await FetchApi(url, "POST", {...data, user_id: user_id});
        if (response && response.status === 200) {
            if (refreshCallback) {
                elementChangeDocument();
                refreshCallback(url)
            } else {
                setValues({...values, Id: response.data});
            }
            if (closeModal) {
                clearModal();
            }
        }
        setLoadings(false);
    };

    const write = () => {
        let obj = {};
        switch (currentPage.path) {
            case "/postupleniya":
            case "/zborka":
                obj = {
                    ...values,
                    jami: DocumentClass(values.document, "postupleniya-foot").jami_miqdor + ""
                }
                RequestJson(currentPage.path, obj, false, false);
                break;
        }
    };

    const writeAndClose = () => {
        let obj = {};
        switch (currentPage.path) {
            case "/postupleniya":
            case "/zborka":
                obj = {
                    ...values,
                    jami: DocumentClass(values.document, "postupleniya-foot").jami_miqdor + ""
                }
                console.log(obj)
                RequestJson(currentPage.path, obj, getMainTableData, true);
                break;
        }
    };

    return (
        <>
            <form onSubmit={e => {
                e.preventDefault();
                handleSubmit();
            }}>
                <div className="form_container">
                    {
                        <ModalInputs forms={secondaryModal.open ? secondaryModal.forms : currentPage.forms}>
                            {" "}
                        </ModalInputs>
                    }
                </div>
            </form>
            <div className="form_submit">
                <div style={{display: "flex", justifyContent: "center", gridGap: "10px"}}>
                    {
                        currentPage.path === "/postupleniya" ||
                        currentPage.path === "/zborka" ?
                            <>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => clearModal()}
                                    className="form-btn">
                                    Закрыть
                                </Button>
                                <Button
                                    onClick={write}
                                    type="ghost"
                                    className="form-btn">
                                    Записать
                                </Button>
                                <Button
                                    onClick={writeAndClose}
                                    type="primary"
                                    className="form-btn">
                                    Записать и закрыть
                                </Button>
                            </>
                            :
                            <>
                                <Button className={"form-btn btn-back"} onClick={() => clearModal()}>
                                    Ортга
                                </Button>
                                <Button
                                    loading={loadings}
                                    className={"form-btn btn-submit"}
                                    onClick={handleSubmit}>
                                    Сақлаш
                                </Button>
                            </>
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        values: state.app.secondaryModal.open
            ? state.app.values2
            : state.app.values,
        modal: state.app.modal,
        actualValues: state.app.values,
        mainTableData: state.app.mainTableData,
        rowId: state.app.rowId,
        currentPage: state.app.currentPage,
        allData: state.app.allData,
        secondaryModal: state.app.secondaryModal,
    };
};

export default connect(mapStateToProps, {
    setMainTableData,
    getMainTableData,
    setLoading,
    setValues,
    changeAllDocument,
    getInnerData,
})(ModalForm);
