import {
    SELECT,
    STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {RiMapPin2Fill} from "react-icons/ri";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";

const Region = {
    title: "Регион",
    path: "/servis/region",
    icon: <RiMapPin2Fill></RiMapPin2Fill>,
    type: SIMPLE,
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Номи</div>,
            dataIndex: "nomi",
            key: "nomi",
        },
    ],
    allData: [
        {value: "shahar_id", prop: "shaharlar", get: "nomi"}
    ],
    forms: [
        {
            grid: "2fr 1fr",
            inputs: [
                {
                    label: "Номи",
                    name: "nomi",
                    type: STRING,
                    required: true,
                },
                {
                    label: "Шахар / Туман",
                    name: "shahar_id",
                    type: SELECT,
                    required: true,
                    allData: {
                        type: "shaharlar",
                        key: "Id",
                        text: "nomi",
                    },
                },
            ],
        },
    ],
};

export default Region;
