import {Form, Input, Button} from "antd";
import "./Login.css";
import "../../Component/Actions/Actions.css";
import {getLogin} from "../../Actions/AppActions";
import {connect} from "react-redux";
import loginBrand from "../../images/login.svg";

const LoginForm = ({getLogin, loading}) => {
    const {Item} = Form;
    const onFinish = e => {
        getLogin(e);
    };
    return (
        <div className={"login_wrapper"}>
            <Form className={"login_form"} name="basic" onFinish={onFinish}>
                <div className="login_logo">
                    <img height="100" src={loginBrand} alt="logo"/>
                </div>
                <Item
                    label="Login"
                    name="login"
                    rules={[{required: true, message: "Loginni kiriting!"}]}>
                    <Input/>
                </Item>
                <Item
                    label="Parol"
                    name="parol"
                    rules={[{required: true, message: "Parolni kiriting!"}]}>
                    <Input.Password/>
                </Item>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Button
                        loading={loading}
                        htmlType="submit"
                        className={"action_btn main-btn"}>
                        Кириш
                    </Button>
                </div>
            </Form>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.app.loading,
    };
};
export default connect(mapStateToProps, {getLogin})(LoginForm);
