import React from "react";
import CurrencyInput from "react-currency-input-field";
import { connect } from "react-redux";
import { setDocument } from "../../../../Actions/AppActions";
const objectReturn = (value, name) => {
  return {
    target: {
      name: name,
      value: value,
    },
  };
};
const KirimInput = ({ setDocument, name, value, record, allData }) => {
  const handleDocument = (value, name) => {
    // const cheg = allData.constanta ? allData.constanta[0].chegirma : null;
    if (record.valyuta_turi == 1) {
      switch (name) {
        case "kirim_narxi":
          // if (record.chegirma) {
          //   const sotish_val = value * ((100 + record.chegirma * 1) / 100);
          //   setDocument(objectReturn(sotish_val, "sotish_val"));
          // }
          setDocument(objectReturn(value, name));
          break;

        case "sotish_narxi":
          // if (record.chegirma) {
          //   const kirim_val = value * (100 / (100 + record.chegirma * 1));
          //   setDocument(objectReturn(parseInt(kirim_val), "kirim_val"));
          // }
          setDocument(objectReturn(value, name));
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case "kirim_narxi":
          // if (record.chegirma) {
          //   const sotish_som = value * ((100 + record.chegirma * 1) / 100);
          //   setDocument(objectReturn(sotish_som, "sotish_som"));
          // }
          setDocument(objectReturn(value, name));
          break;

        case "sotish_narxi":
          // if (record.chegirma) {
          //   const kirim_som = value * (100 / (100 + record.chegirma * 1));
          //   setDocument(objectReturn(parseInt(kirim_som), "kirim_som"));
          // }
          setDocument(objectReturn(value, name));
          break;
          default:
          break;
      }
    }
  };
  return (
    <CurrencyInput
      value={value}
      decimalsLimit={2}
      decimalSeparator="."
      className="currency_input"
      name={name}
      onValueChange={(value, name) => {
        handleDocument(value, name);
      }}
    />
  );
};
const mapStateToProps = state => {
  return {
    allData: state.app.allData,
  };
};
export default connect(mapStateToProps, { setDocument })(KirimInput);
