import {
    CURRENCY,
    IMAGE,
    NUMBER,
    SELECT,
    STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import Zoom from "react-medium-image-zoom";
import {RiQrScanFill} from "react-icons/ri";
import {BaseUrl} from "../../BaseUrl";
import "react-medium-image-zoom/dist/styles.css";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";
import {pul} from "../../Functions/Constanta";

const Maxsulot = {
    title: "Маҳсулотлар",
    path: "/servis/maxsulot",
    icon: <RiQrScanFill/>,
    type: SIMPLE,
    filters: [
        {
            dataIndex: "brendlar",
        },
        {
            dataIndex: "bolimlar",
        },
    ],
    allData: [
        {value: "brend_id", prop: "brendlar", get: "nomi"},
        {value: "type_id", prop: "bolimlar", get: "nomi"},
        {value: "olchov_id", prop: "olchov_turlari", get: "nomi"},
    ],
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Номи</div>,
            dataIndex: "nomi",
            key: "nomi",
            width: "153px",
        },
        {
            title: <div className="table_head">Тартиб</div>,
            dataIndex: "tartib",
            key: "nomi",
            width: "120px",
        },
        {
            title: <div className="table_head">Бренд</div>,
            dataIndex: "brendlar",
            key: "brendlar",
            onFilter: (value, record) => record.brendlar?.indexOf(value) === 0,
            width: "117px",
        },
        {
            title: <div className="table_head">Ўлчов бир</div>,
            dataIndex: "olchov_turlari",
            key: "olchov_turlari",
            width: "101px",
        },
        {
            title: <div className="table_head">Блок сони</div>,
            dataIndex: "blok_soni",
            key: "blok_soni",
            width: "101px",
        },
        {
            title: <div className="table_head">Вазни</div>,
            dataIndex: "vazni",
            key: "vazni",
            width: "79px",
        },
        // {
        //   title: <div className="table_head">Вал тури</div>,
        //   dataIndex: "valyuta_turi",
        //   key: "valyuta_turi",
        //   width: "79px",
        //   filters: [
        //     { text: pul.valyuta, value: "1" },
        //     { text: pul.som, value: "2" },
        //   ],
        //   onFilter: (value, record) => record.valyuta_turi == value,
        //   render: text => (text === 1 ? pul.valyuta : pul.som),
        // },
        {
            title: <div className="table_head">Кирим нархи</div>,
            dataIndex: "kirim_narxi",
            key: "kirim_narxi",
            width: "100px",
        },
        {
            title: <div className="table_head">Сотиш нархи</div>,
            dataIndex: "sotish_narxi",
            key: "sotish_narxi",
            width: "100px",
        },
        {
            title: <div className="table_head">Расми</div>,
            dataIndex: "img",
            key: "img",
            width: "70px",
            render: text => {
                if (text) {
                    return (
                        <Zoom zoomMargin={40}>
                            <picture>
                                <source
                                    media="max-width: 200px"
                                    srcSet={BaseUrl + text.slice(1)}
                                />
                                <img src={BaseUrl + text.slice(1)} alt="asda" height={30}/>
                            </picture>
                        </Zoom>
                    );
                } else return "";
            },
        },
    ],

    forms: [
        {
            grid: "3fr 2fr ",
            inputs: [
                {
                    label: "Номи",
                    name: "nomi",
                    type: STRING,
                    required: true,
                },
                // {
                //   label: "Тартиб",
                //   name: "tartib",
                //   type: NUMBER,
                //   required: true,
                // },
                {
                    label: "Бренд",
                    name: "brend_id",
                    type: SELECT,
                    required: true,
                    allData: {
                        type: "brendlar",
                        key: "Id",
                        text: "nomi",
                    },
                },
            ],
        },
        {
            grid: "2fr 2fr 2fr",
            inputs: [
                {
                    label: "Ўлчов бирлиги",
                    name: "olchov_id",
                    type: SELECT,
                    required: true,
                    allData: {
                        type: "olchov_turlari",
                        key: "Id",
                        text: "nomi",
                    },
                },
                {
                    label: "Блок сони",
                    name: "blok_soni",
                    type: NUMBER,
                    required: true,
                },
                // {
                //   label: "Вазни",
                //   name: "vazni",
                //   type: NUMBER,
                //   required: true,
                // },
                // {
                //   label: "Валюта тури",
                //   name: "valyuta_turi",
                //   type: SELECT,
                //   required: true,
                //   costumData: [
                //     { Id: 1, nomi: pul.valyuta },
                //     { Id: 2, nomi: pul.som },
                //   ],
                // },
                {
                    label: "Расм",
                    name: "img",
                    type: IMAGE,
                },
            ],
        },

        {
            grid: "2fr 2fr 4fr",
            inputs: [
                {
                    label: "Кирим нархи",
                    name: "kirim_narxi",
                    type: CURRENCY,
                    required: true,
                },

                {
                    label: "Сотиш нархи",
                    name: "sotish_narxi",
                    type: CURRENCY,
                    required: true,
                },
                // {
                //   label: "Чегирма %",
                //   name: "chegirma_foiz",
                //   type: NUMBER,
                // },
            ],
        },
    ],
};
export default Maxsulot;
