import {
    RiCustomerServiceFill,
    RiDatabase2Fill,
    RiHome2Fill,
    RiShoppingBag3Fill,
    RiVipDiamondFill,
} from "react-icons/ri";
import {SERVICES, SIMPLE, SPRAVOCHNIK} from "../Component/Sidebar/PageTypes";
import Postupleniya from "./Savdo/Postupleniya";
import Peremesheniye from "./Savdo/Peremesheniye";
import Продажа from "./Savdo/Prodaja";
import Zborka from "./Savdo/Zborka";

const PagesClass = [
    {
        title: "Главная",
        path: "/",
        disableFetch: true,
        icon: <RiHome2Fill/>,
        type: SIMPLE,
    },
    {
        title: "Склад",
        key: "sub1",
        icon: <RiShoppingBag3Fill/>,
        submenu: [Postupleniya, Zborka, Peremesheniye],
    },
    Продажа,
    {
        title: "Справочник",
        path: "/spravochnik",
        disableFetch: true,
        icon: <RiVipDiamondFill/>,
        type: SPRAVOCHNIK,
    },
    {
        title: "Отчеты",
        path: "/otcheti",
        icon: <RiDatabase2Fill/>,
        type: SERVICES,
    },
    {
        title: "Сервис",
        path: "/servis",
        icon: <RiCustomerServiceFill/>,
        type: SERVICES,
    },
    // {
    //     key: "sub2",
    //     title: "Қайтарилганлар",
    //     icon: <RiReplyAllFill/>,
    //     submenu: [Savdodan, YukVozvrat],
    // },
    // {
    //     key: "sub3",
    //     title: "Тўловлар",
    //     icon: <RiPaypalFill/>,
    //     submenu: [MijozTolov, AgentTolov, YukBeruvchiTolov],
    // },
    // {
    //     key: "sub4",
    //     title: "Харажатлар",
    //     icon: <RiPaypalFill/>,
    //     submenu: [XarajatTurlari, XarajatTolov],
    // },
];

export default PagesClass;
