import Modal from "antd/lib/modal/Modal";
import {connect} from "react-redux";
import {
    setInnerModal2,
    setRowId,
    setValues,
    getInnerHeadData,
    setInnerData,
    setSmallModal, getAllData, setValues3,
} from "../../Actions/AppActions";
import "./Modal.css";
import ModalTable from "../Tables/ModalTable/ModalTable";
import React, {useEffect, useState} from "react";
import SearchCom from "../Header/SearchCom";
import {RiCloseCircleFill} from "react-icons/ri";
import NewHeadActions from "../Actions/newHeadActions/NewHeadActions";
import NewHeadSmallModal from "./NewHeadSmallModal/NewHeadSmallModal";
import FetchApi from "../../Functions/fetchApi";
import moment from "moment";

const InnerModal2 = ({
                         modal,
                         setInnerModal2,
                         setSmallModal,
                         setRowId,
                         allData,
                         setValues3,
                         getAllData,
                         innerSmallModal,
                         values3,
                         innerDataType,
                         setValues,
                         currentPage,
                         getInnerHeadData,
                         innerDataHead,
                         // innerData,
                         values,
                     }) => {
    const [dataSource, setDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    const [title, setTitle] = useState([]);

    const clearModal = () => {
        setInnerModal2(false);
        setRowId(null);
    };

    const headType = innerDataType;

    useEffect(async () => {
        if (innerDataType) {
            setTitle(headType.modalTitle);
            setColumns(headType.mainData && headType.mainData.columns);
            await getInnerHeadData([headType.dataType]);
            // await getAllData(allData);
            if (currentPage && currentPage.allData && currentPage.allData.length > 0) {
                getAllData(currentPage && currentPage.allData.map(al => al && al.prop));
            }

        }
    }, [currentPage, innerDataType]);

    useEffect(() => {
        // console.log(values);
        RequestJson("/spravochnik/detali/barcode")
        setDataSource(allData[headType && headType.dataType]);
    }, [allData, modal]);


    const RequestJson = async (url, data) => {
        const user = JSON.parse(window.localStorage.getItem("user"));
        const user_id = user.id || null;

        // setLoadings(true);
        const response = await FetchApi(url, "GET", {...data, user_id: user_id});
        if (response && response.status === 200) {
            let obj = {};
            switch (headType && headType.mainData.path) {
                case "/spravochnik/detali":
                    obj = {
                        ...values3,
                        barcode: "" + (response.data[0] && +response.data[0].barcode + 1 || 250000000000),
                    }
                    break;
            }
            setValues3(obj)
        }
        // setLoadings(false);
    };

    let modalStyle = {
        backStyle: {
            backgroundColor: "#eee",
            padding: "10px",
            borderRadius: "2px",
        },
        flexBetween: {
            display: "flex",
            justifyContent: "space-between",
        },
        closeIcon: <RiCloseCircleFill/>
    }

    return (
        <Modal centered
               title={title}
               visible={modal}
               footer={null}
               width="900px"
               onCancel={clearModal}
               closeIcon={modalStyle.closeIcon}>
            <div style={modalStyle.backStyle}>
                <div style={modalStyle.flexBetween}>
                    <NewHeadActions setColumns={setColumns}/>
                    <SearchCom columns={columns} setS={setDataSource} newHeadData={dataSource}/>
                </div>
                <ModalTable
                    innerTable={true}
                    columns={columns}
                    clearModal={clearModal}
                    innerTableData={dataSource}/>
                <NewHeadSmallModal setColumns={setColumns} setData={setDataSource}/>
            </div>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        values: state.app.values,
        values3: state.app.values3,
        allData: state.app.allData,
        loading: state.app.loading,
        modal: state.app.innerModal2,
        currentPage: state.app.currentPage,
        innerDataType: state.app.innerDataType,
        innerDataHead: state.app.innerDataHead,
        innerSmallModal: state.app.innerSmallModal,
    };
};

export default connect(mapStateToProps, {
    setValues,
    setValues3,
    getAllData,
    setSmallModal,
    getInnerHeadData,
    setInnerModal2,
    setInnerData,
    setRowId,
})(InnerModal2);
