import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar/Sidebar";
import PagesClass from "./PagesClass";
import LoginForm from "./LoginPages/LoginForm";
import { connect } from "react-redux";

const Main = ({ auth }) => {
  const [user, setUser] = useState();
  useEffect(() => {
    setUser(JSON.parse(window.localStorage.getItem("user")));
  }, [auth]);

  let main = null;

  if (user) {
    main = <Sidebar pages={PagesClass}/>;
  } else {
    main = <LoginForm />;
  }

  return main;
};
const mapStateToProps = state => {
  return {
    auth: state.app.auth,
  };
};
export default connect(mapStateToProps)(Main);
