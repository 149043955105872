import React from "react";
import MainTable from "../../Tables/MainTable";
import Actions from "../../Actions/Actions";

const SkladPage = ({searchDataSource}) => {
    return (
        <>
            <Actions/>
            <MainTable costumColumns={false} data={searchDataSource}/>
        </>
    );
};

export default SkladPage;
