import {Table} from "antd";
import {connect} from "react-redux";
import {
    changeDocumentObj,
    setInnerModal,
    setRowId,
    setRowId2,
    setValues,
    setModal,
    stateDetector,
} from "../../Actions/AppActions";
import "./Table.css";
import Text from "antd/lib/typography/Text";
import JamiClass from "../../Functions/JamiClass";
import FilterColumns from "../../Functions/FilterColumns";
import NumberFormat from "../../Functions/NumberFormat";
import {useHistory} from "react-router";
import {useEffect} from "react";

const SecondTable = ({
                         columns,
                         costumColumns,
                         data,
                         setRowId,
                         id,
                         rowId2,
                         setRowId2,
                         clearModal,
                         rowId,
                         innerModal2,
                         loading,
                         innerDataType,
                         values,
                         summary,
                         changeDocumentObj,
                         innerTable,
                         setInnerModal,
                         notPag,
                         currentPage,
                         stateDetector,
                         modal,
                         setValues,
                         setModal,
                         mainTableData,
                         allData,
                     }) => {
    const {location} = useHistory();
    const onClickRow = (record, index) => {
        return {
            onClick: () => {
                // let trs = document.getElementsByClassName("inner-modal-row");
                // if (trs) {
                //     for (let i = 0; i < trs.length; i++) {
                //         trs && trs[i].classList.remove("selected-tr");
                //     }
                //     trs && trs[index] && trs[index].classList.add("selected-tr");
                // }

                if (!innerTable) {
                    setRowId(record.Id);
                } else {
                    setRowId2(record.Id);
                }
            },
            onDoubleClick: () => {
                if (innerTable) {
                    if (innerModal2 && currentPage.header) {
                        if (innerDataType.dataType === "postavshiki") {
                            setValues({...values, postavshik: record.Id});
                        } else if (innerDataType.dataType === "skladi") {
                            setValues({...values, sklad: record.Id});
                        } else if (innerDataType.dataType === "modeli") {
                            setValues({...values, model: record.Id});
                        } else if (innerDataType.dataType === "mebeli") {
                            let modelName = allData["mebeli"]?.find(el => el.Id === record.Id).model;
                            let modelId = allData["modeli"]?.find(el => el.nomi === modelName).Id;
                            setValues({...values, mebel: record.Id, model: modelId});
                        } else if (innerDataType.dataType === "detali") {
                            changeDocumentObj({...record, element_id: record.Id,}, rowId);
                        } else if (innerDataType.dataType === "ostatka") {
                            changeDocumentObj({...record, element_id: record.Id,}, rowId);
                        }
                        clearModal();
                    }
                    setInnerModal(false);
                    stateDetector();
                } else {
                    if (!modal) {
                        setRowId(record.Id);
                        let obj = mainTableData.find(d => d.Id === record.Id);
                        setValues(obj);
                        setModal(true);
                    }
                }
            },
        };
    };
    const setRowClassName = (record, index) => {
        let className = "";
        if (record.Id === rowId || record.Id === rowId2) {
            className = className + "clickRowStyl";
        }
        if (data.length - 1 === index || data.length - 1 === index) {
            className = className + " scroll-row";
        }
        // return innerTable ? className + " inner-modal-row" : className;
        return className + " inner-modal-row";
    };

    const tableFooter = () => {
        if ((currentPage.modalJami && modal && !innerTable) || summary) {
            return (
                <Table.Summary fixed="bottom">
                    <Table.Summary.Row>
                        <Table.Summary.Cell>Жами:</Table.Summary.Cell>
                        {JamiClass(currentPage.modalJami, data).map(d => (
                            <Table.Summary.Cell style={{textAlign: "right"}}>
                                <Text>{d ? NumberFormat(d.toFixed(2)) : ""}</Text>
                            </Table.Summary.Cell>
                        ))}
                    </Table.Summary.Row>
                </Table.Summary>
            );
        } else return null;
    };
    let filteredColumns = [];
    if (currentPage.filters) {
        filteredColumns = FilterColumns(currentPage.filters, columns, data);
    } else {
        filteredColumns = columns;
    }
    useEffect(() => {
        // console.log("bu haqiqiy data");
    }, [data])
    return (
        <>
            <div className={"main_table"}>
                <Table
                    size="small"
                    pagination={
                        notPag
                            ? false
                            : {
                                pageSizeOptions: ["10", "100", data.length],
                                showLessItems: true,
                                size: "small",
                                showSizeChanger: true,
                            }
                    }
                    bordered
                    columns={costumColumns ? costumColumns : filteredColumns}
                    dataSource={data}
                    id={id || ""}
                    loading={loading}
                    // scroll={notX ? false : { x: 900 }}
                    scroll={{y: 250, x: 0}}
                    onRow={onClickRow}
                    rowSelection={null}
                    rowClassName={setRowClassName}
                    summary={tableFooter}
                />
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        rowId: state.app.rowId,
        rowId2: state.app.rowId2,
        innerSmallModal: state.app.innerSmallModal,
        values: state.app.values,
        allData: state.app.allData,
        loading: state.app.loading,
        columns: state.app.currentPage.columns,
        currentPage: state.app.currentPage,
        innerModal2: state.app.innerModal2,
        innerDataType: state.app.innerDataType,
        modal: state.app.modal,
        mainTableData: state.app.mainTableData,
    };
};
export default connect(mapStateToProps, {
    setRowId,
    changeDocumentObj,
    setInnerModal,
    setValues,
    setModal,
    setRowId2,
    stateDetector,
})(SecondTable);
