import {RiBriefcaseFill} from "react-icons/ri";
import MaxsulotQoldigi from "../Savdo/MaxsulotQoldigi";
import MijozQoldigi from "../Savdo/MijozQoldigi";
import YukBeruvchilarQoldigi from "../Savdo/YukBeruvchilarQoldigi";
import Bolimlar from "./Bolimlar";
import Model from "./Model";
import Maxsulot from "./Maxsulot";
import Mijozlar from "./Klienti";
import OlchovBirligi from "./OlchovBirligi";
import Region from "./Region";
import Shahar from "./Shahar";
import Viloyat from "./Viloyat";
import Xodimlar from "./Xodimlar";
import yukBeruvchi from "./yukBeruvchi";
import Klienti from "./Klienti";
import Postavshiki from "./Postavshiki";
import Sklad from "./Components/Sklad";
import Detal from "./Detal";
import Mebel from "./Мебел";
import SotsialniySet from "./SotsialniySet";
import Sotrudniki from "./Sotrudniki";

export default () => {
    return [
        Klienti,
        Postavshiki,
        Model,
        Sklad,
        Detal,
        Mebel,
        SotsialniySet,
        Sotrudniki,
        // {
        //   title: "Лавозим",
        //   path: "/servis/lavozim",
        //   icon: <RiBriefcaseFill></RiBriefcaseFill>,
        // },
    ];
};
