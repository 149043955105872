import {
    IMAGE,
    MODALTABLE,
    SELECT,
    STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {RiVipCrownFill} from "react-icons/ri";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";
import {BaseUrl} from "../../BaseUrl";
import Zoom from "react-medium-image-zoom";

const Sotrudniki = {
    title: "Сотрудники",
    path: "/spravochnik/sotrudniki",
    icon: <RiVipCrownFill/>,
    type: SIMPLE,
    modalWidth: "900px",
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Номи</div>,
            dataIndex: "nomi",
            key: "nomi",
            width: "132px",
        },
        {
            title: <div className="table_head">Логин</div>,
            dataIndex: "login",
            key: "login",
            width: "132px",
        },
        {
            title: <div className="table_head">Пароль</div>,
            dataIndex: "parol",
            key: "parol",
            width: "132px",
        },
        {
            title: <div className="table_head">Склад</div>,
            dataIndex: "skladi",
            key: "nomi",
            width: "132px",
        },
        {
            title: <div className="table_head">Доступ</div>,
            dataIndex: "dostup",
            key: "dostup",
            width: "132px",
        },
    ],
    allData: [
        {value: "sklad", prop: "skladi", get: "nomi"},
        // {value: "postavshiki", prop: "postavshiki", get: "nomi"},
    ],
    forms: [
        {
            grid: "1fr 1fr 1fr",
            inputs: [
                {
                    label: "Номи",
                    name: "nomi",
                    type: STRING,
                    required: true,
                    autoGetFocus: true,
                },
                {
                    label: "Логин",
                    name: "login",
                    type: STRING,
                    required: true,
                },
                {
                    label: "Пароль",
                    name: "parol",
                    type: STRING,
                    required: true,
                },
            ],
        },
        {
            grid: "1fr 1fr",
            inputs: [
                {
                    label: "Склад",
                    name: "sklad",
                    type: SELECT,
                    required: true,
                    allData: {
                        type: "skladi",
                        key: "Id",
                        text: "nomi",
                    }
                },
                {
                    label: "Доступ",
                    name: "dostup",
                    type: STRING,
                },
            ],
        },
    ],
};
export default Sotrudniki;
