import moment from "moment";
import React, {useEffect} from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import NumberFormat from "../../Functions/NumberFormat";
import "./print.css";
import PrintTable from "./PrintTable";
import {useHistory} from "react-router";
import loginBrand from "../../images/print_logo.png";
import {RiArrowGoBackFill} from "react-icons/ri";

const PrintContainer = ({rowId, mainTableData, allData, type}) => {
    const history = useHistory();
    const [info, setInfo] = useState({});
    useEffect(() => {
        if (rowId && mainTableData.length > 0) {
            if (mainTableData.find(d => d.Id == rowId)) {
                const obj = mainTableData.find(d => d.Id == rowId);
                let dolg_after = 0;
                obj.document.forEach(d => {
                    dolg_after += d.miqdor_soni * 1 * d.sotish_narxi * 1;
                });
                const mij = allData.mijozlar.find(m => m.Id == obj.mijoz_id)
                    ? allData.mijozlar.find(m => m.Id == obj.mijoz_id)
                    : {};
                const agent = allData.agentlar.find(m => m.Id == obj.agent_id)
                    ? allData.agentlar.find(m => m.Id == obj.agent_id)
                    : {};
                const newObj = {
                    qarzi_som: obj.qarzi_som,
                    qarzi_val: obj.qarzi_val,
                    dolg_after: dolg_after,
                    time: obj.time_update,
                    mijoz_fio: mij.fio,
                    mijoz_telefon: mij.telefon,
                    agent_fio: agent.fio,
                    agent_telefon: agent.telefon,
                };
                setInfo(newObj);
            }
        } else {
            window.location.href = "/sotuv-bolimi";
        }
    }, [rowId]);
    if (rowId) {
        return (
            <div className="print_container">
                <div>
                    <RiArrowGoBackFill
                        onClick={() => history.goBack()}
                        style={{color: "blue", cursor: "pointer"}}
                    />
                </div>
                <span className="print_header">
          <img width="200" src={loginBrand} alt="logo"/>
          <div>{type !== "sotuv" && "Возврат"}</div>
        </span>
                <div className="print_body_container">
          <span className="print_user">
            <p
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
              <div>
                Мижоз:
                <span> {info.mijoz_fio}</span>
                <span>_ _ _ +{info.mijoz_telefon}</span>
              </div>
              <div>
                Сана:{" "}
                  <span>{moment(info.time).format("DD.MM.YYYY HH:mm:ss")}</span>
              </div>
            </p>
            <p
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
              <div>
                Агент:
                <span> {info.agent_fio}</span>
              </div>
              <div>Долг сўм: {NumberFormat(info.qarzi_som)}</div>
            </p>
          </span>
                </div>
                <PrintTable/>
                <h4
                    style={{
                        textAlign: "right",
                        margin: 0,
                        marginRight: "30px",
                        fontWeight: "700",
                    }}
                >
                    Долг сўм:{" "}
                    {NumberFormat(
                        info.qarzi_som * 1 + (type === "sotuv" ? 1 : -1 * info.dolg_after)
                    )}
                </h4>
                {type === "sotuv" && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "20px",
                        }}
                    >
                        <h4 style={{fontWeight: "700"}}>
                            Доставщик _____________________________________________
                        </h4>
                        <h4 style={{fontWeight: "700"}}>
                            Мижоз _____________________________________________
                        </h4>
                    </div>
                )}
            </div>
        );
    } else {
        return <Redirect to="/sotuv-bolimi"></Redirect>;
    }
};
const mapStateToProps = state => {
    return {
        rowId: state.app.rowId,
        mainTableData: state.app.mainTableData,
        allData: state.app.allData,
    };
};

export default connect(mapStateToProps)(PrintContainer);
