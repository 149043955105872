import {
    BARCODE_INPUT,
    IMAGE,
    SELECT,
    STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {RiUser2Fill} from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";
import Zoom from "react-medium-image-zoom";
import Barcode from "react-barcode";
import {BaseUrl} from "../../BaseUrl";

const Detal = {
    title: "Детал",
    path: "/spravochnik/detali",
    icon: <RiUser2Fill/>,
    type: SIMPLE,
    modalWidth: "900px",
    allData: [
        {value: "model_id", prop: "modeli", get: "nomi"},
        {value: "postavshik_id", prop: "postavshiki", get: "fullName"},

    ],
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Номи</div>,
            dataIndex: "nomi",
            key: "nomi",
            width: "152px",
        },
        {
            title: <div className="table_head">Модел</div>,
            dataIndex: "model",
            key: "nomi",
            width: "158px",
        },
        {
            title: <div className="table_head">Штрих код</div>,
            dataIndex: "barcode",
            key: "barcode",
            width: "100px",
            align: "center",
            render: (name) => (
                <Barcode width={"1px"}
                         height={"40px"}
                         margin={0}
                         displayValue={false}
                         value={name}/>
            )
        },
        {
            title: <div className="table_head">Фото</div>,
            dataIndex: "img",
            key: "img",
            width: "70px",
            render: text => {
                if (text) {
                    return (
                        <Zoom zoomMargin={40}>
                            <picture>
                                <source
                                    media="max-width: 200px"
                                    srcSet={BaseUrl + text.slice(1)}
                                />
                                <img src={BaseUrl + text.slice(1)} alt="Детал расми" height={30}/>
                            </picture>
                        </Zoom>
                    );
                } else return "";
            },
        },
    ],
    forms: [
        {
            grid: "1fr 1fr",
            inputs: [
                {
                    label: "Номи",
                    name: "nomi",
                    type: STRING,
                    autoGetFocus: true,
                    required: true,
                },
                {
                    label: "Модел",
                    name: "model",
                    type: SELECT,
                    required: true,
                    allData: {
                        type: "modeli",
                        key: "nomi",
                        text: "nomi"
                    }
                }
            ],
        },
        {
            grid: "1fr 1fr",
            inputs: [
                {
                    label: "Штрих ID",
                    name: "barcode",
                    type: BARCODE_INPUT,
                    required: true,
                    readOnly: true,
                },
                {
                    label: "Расм",
                    name: "img",
                    type: IMAGE,
                },
            ],
        },
    ],
};
export default Detal;
