import React, {useEffect, useRef} from "react";
import {
    RiAddLine,
    RiCloseLine,
    RiPencilLine,
} from "react-icons/ri";
import {Button, message, Popconfirm} from "antd";
import {useState} from "react";
import "./Actions.css";
import {
    setModal,
    getMainTableData,
    setValues,
    setRowId,
    setCurrentPage, setSmallModal,
} from "../../Actions/AppActions";
import {connect} from "react-redux";
import fetchApi from "../../Functions/fetchApi";
import {useHistory} from "react-router-dom";
import FetchApi from "../../Functions/fetchApi";
import moment from "moment";

const Actions = ({
                     setModal,
                     rowId,
                     data,
                     values,
                     setValues,
                     currentPage,
                     getMainTableData,
                     allData,
                     setRowId,
                 }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        document.addEventListener("keydown", KeyboardEventss);

        return () => {
            document.removeEventListener("keydown", KeyboardEventss);
        };
    }, []);

    const KeyboardEventss = event => {
        switch (event.key) {
            case "Insert":
                // openAdd();
                break;

            default:
                break;
        }
    };
    const RequestJson = async (url, data) => {
        let user_id = null;
        if (JSON.parse(window.localStorage.getItem("user")) &&
            JSON.parse(window.localStorage.getItem("user")).Id) {
            user_id = JSON.parse(window.localStorage.getItem("user")).Id;
        }
        // setLoadings(true);
        const response = await FetchApi(url, "GET", {...data, user_id: user_id});
        if (response && response.status === 200) {
            let obj = {};
            switch (currentPage.path) {
                case "/postupleniya":
                    obj = {
                        ...values,
                        unikalId: response.data[0] && +response.data[0].unikalId + 1 || 100000001,
                        sana: values.sana ? values.sana : moment(new Date(), 'YYYY-MM-DD'),
                        postavshik_id: "",
                        jami: "",
                        sklad: "",
                        izoh: "",
                        document: []
                    }
                    break;
                case "/zborka":
                    obj = {
                        ...values,
                        unikalId: response.data[0] && +response.data[0].unikalId + 1 || 100000001,
                        sana: values.sana ? values.sana : moment(new Date(), 'YYYY-MM-DD'),
                        jami: "",
                        mebel: "",
                        model: "",
                        sklad: "",
                        izoh: "",
                        document: []
                    }
                    break;
                case "/spravochnik/detali":
                    obj = {
                        ...values,
                        barcode: "" + (response.data[0] && +response.data[0].barcode + 1 || 250000000000),
                    }
                    break;
            }
            setValues(obj)
        }
        // setLoadings(false);
    };
    const openAdd = () => {
        switch (currentPage.path) {
            case "/postupleniya":
                RequestJson("/postupleniya/unikalId")
                break;
            case "/zborka":
                RequestJson("/zborka/unikalId")
                break;
            case "/spravochnik/detali":
                RequestJson("/spravochnik/detali/barcode")
                break;
        }

        if (currentPage.defaultValues && currentPage.defaultValues.length > 0) {
            let obj = {};
            currentPage.defaultValues.forEach(d => {
                obj[d.prop] = d.value;
            });
            setValues(obj);
        }
        setRowId(null);
        setModal(true);
    };

    const {location} = useHistory();
    const openEdit = () => {
        if (rowId) {
            let obj = data.find(d => d.Id === rowId);
            if (
                location.pathname === "/servis/brendlar" &&
                currentPage.defaultDocument
            ) {
                obj = {
                    ...obj,
                    document: allData[currentPage.defaultDocument.allData]
                        .filter(d => d[currentPage.defaultDocument.prop] == rowId)
                        .map(m => {
                            return {...m, element_id: m.Id};
                        }),
                };
            }
            setValues(obj);
            setModal(true);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };
    const handleDelete = async () => {
        let user_id = null;
        if (
            JSON.parse(window.localStorage.getItem("user")) &&
            JSON.parse(window.localStorage.getItem("user")).id
        ) {
            user_id = JSON.parse(window.localStorage.getItem("user")).id;
        }
        if (rowId) {
            setLoading(true);
            const res = await fetchApi(currentPage.path + "/" + rowId, "DELETE", {
                user_id: user_id,
            });
            if (res && res.status === 200) {
                setRowId(null);
                getMainTableData(currentPage.path);
            }
            setVisible(false);
            setLoading(false);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };

    return (
        <div style={{display: "flex"}} className="body_actions">
            <Button onClick={openAdd} type="primary" className="action_btn main-btn">
                <RiAddLine className="action_icon"/> &nbsp;{" "}
                {/*<span className="action_text">Қўшиш</span>*/}
                <span className="action_text">Добавить</span>
            </Button>
            &nbsp; &nbsp;
            <Button onClick={openEdit} type="primary" className="action_btn edit-btn">
                <RiPencilLine className="action_icon"/> &nbsp;{" "}
                {/*<span className="action_text">Ўзгартириш</span>*/}
                <span className="action_text">Изменить</span>
            </Button>
            &nbsp; &nbsp;
            <Popconfirm
                title="Маьлумот ўчирилади!"
                visible={visible}
                onConfirm={handleDelete}
                okButtonProps={{loading: loading}}
                onCancel={() => setVisible(false)}>
                <Button
                    icon
                    loading={loading}
                    className="action_btn delete-btn"
                    onClick={() => {
                        if (rowId) {
                            setVisible(true);
                        } else message.warning("Qatorni tanlang!");
                    }}>
                    <RiCloseLine className="action_icon"/> &nbsp;{" "}
                    {/*<span className="action_text">Ўчириш</span>*/}
                    <span className="action_text">Удалить</span>
                </Button>
            </Popconfirm>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        rowId: state.app.rowId,
        data: state.app.mainTableData,
        loading: state.app.loading,
        values: state.app.values,
        allData: state.app.allData,
        currentPage: state.app.currentPage,
    };
};

export default connect(mapStateToProps, {
    setModal,
    setSmallModal,
    getMainTableData,
    setValues,
    setRowId,
    setCurrentPage,
})(Actions);
