import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import {HEAD_DATE, HEAD_INPUT} from "./skladConstants";
import {DatePicker, Input} from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/ru_RU";
import {
    setValues
} from "../../../Actions/AppActions";

const makeField = ({field, values, setValues}) => {
    const changeDatePicker = (e) => {
        setValues({...values, sana: e.target.value})
    }

    switch (field && field.type) {
        case HEAD_INPUT:
            return (
                <Input
                    name={field.name}
                    value={field.value}
                    readOnly={field.readOnly}
                    placeholder={field.placeholder}/>)
        case HEAD_DATE:
            return (
                <DatePicker
                    showTime
                    // values={values["sana"] || moment(new Date(), 'YYYY-MM-DD')}
                    defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                    format="DD.MM.YYYY HH:mm"
                    allowClear={false}
                    locale={locale}
                    onChange={v => {
                        const e = {
                            target: {
                                name: field.name,
                                value: moment(v).format("YYYY-MM-DD HH:mm:ss"),
                            },
                        };
                        changeDatePicker(e)
                    }}
                />
            );
    }
};


const mapStateToProps = state => {
    return {
        values: state.app.secondaryModal.open
            ? state.app.values2
            : state.app.values,
    };
};

export default connect(mapStateToProps, {
    setValues,
})(makeField);
