import MiqdorInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorInput";
import { BaseUrl } from "../../BaseUrl";
import { RiStackFill } from "react-icons/ri";
import {
  DATE,
  MODALTABLE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import DocumentClass from "../../Functions/DocumentClass";
import moment from "moment";
import NumberFormat from "../../Functions/NumberFormat";
import ChangeMaxsulot from "./Components/ChangeMaxsulot";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import ShowAllBtn from "./Components/ShowAllBtn";
const MaxsulotQoldigi = {
  title: "Маҳсулот қолдиғи",
  path: "/maxsulot-qoldigi",
  type: SIMPLE,
  icon: <RiStackFill/>,
  allData: [
    { value: "maxsulot_id", prop: "tovarlar", get: "nomi" },
    { value: "yuk_beruvchi_id", prop: "yuk_beruvchilar", get: "fio" },
  ],
  modalJami: "maxsulot-qoldigi",
  innerData: "tovarlar",
  modalWidth: "1336px",
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Сана</div>,
      dataIndex: "time_create",
      key: "nomi",
      render: text => moment(text).format("DD.MM.YYYY HH:mm"),
      width: "120px",
    },
    {
      title: <div className="table_head">Кирим сумма</div>,
      dataIndex: "document",
      key: "nomi",
      width: "150px",
      align: "right",
      render: text =>
        NumberFormat(
          DocumentClass(text, "maxsulot-qoldigi").kirim_summa?.toFixed(1)
        ),
    },
    {
      title: <div className="table_head">Сотиш сумма</div>,
      dataIndex: "document",
      key: "nomi",
      width: "150px",
      align: "right",
      render: text =>
        NumberFormat(
          DocumentClass(text, "maxsulot-qoldigi").sotish_summa?.toFixed(1)
        ),
    },
    {
      title: <div className="table_head">Жами миқдор</div>,
      dataIndex: "document",
      key: "nomi",
      width: "150px",
      align: "right",
      render: text => DocumentClass(text, "maxsulot-qoldigi").miqdor_summa,
    },
    {
      title: <div className="table_head">Изох</div>,
      dataIndex: "izoh",
      key: "nomi",
      width: "178px",
    },
  ],
  forms: [
    {
      type: "document",
      grid: "1fr",
      actionInputs: [
        {
          grid: "2fr 4fr",
          inputs: [
            {
              label: "Сана",
              name: "time_create",
              type: DATE,
            },
            {
              label: "Изох",
              name: "izoh",
              type: STRING,
            },
          ],
        },
      ],
      inputs: [
        {
          name: "document",
          type: MODALTABLE,
          innerModal: {
            title: "Маҳсулотлар",
            columns: [
              {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "50px",
                align: "center",
              },
              {
                title: <div className="table_head">Маҳсулот</div>,
                dataIndex: "nomi",
                key: "nomi",
              },
              {
                title: <div className="table_head">Блок сони</div>,
                dataIndex: "blok_soni",
                key: "blok_soni",
                width: "101px",
              },
              {
                title: <div className="table_head">Расми</div>,
                dataIndex: "img",
                key: "img",
                render: text => {
                  if (text) {
                    return (
                      <picture>
                        <source
                          media="max-width: 200px"
                          srcSet={BaseUrl + text.slice(1)}
                        />
                        <img
                          src={BaseUrl + text.slice(1)}
                          alt="asda"
                          height={30}
                        />
                      </picture>
                    );
                  } else return "";
                },
              },
            ],
          },

          costumColumns: [
            {
              title: <div className="table_head">№</div>,
              key: "index",
              render: (text, record, index) => index + 1,
              width: "70px",
              align: "center",
            },
            // {
            //   title: <div className="table_head">Маҳсулот</div>,
            //   dataIndex: "nomi",
            //   key: "nomi",
            //   render: (text, record) => (
            //     <div
            //       style={{
            //         display: "flex",
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //       }}
            //     >
            //       {text} <ChangeMaxsulot record={record}></ChangeMaxsulot>
            //     </div>
            //   ),
            // },
            {
              title: <div className="table_head">Маҳсулот</div>,
              dataIndex: "element_id",
              key: "nomi",
              width: "200px",
              render: text => (
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <MiqdorSelect
                    name={"element_id"}
                    value={text}
                    placeholder="Махсулот"
                    get="nomi"
                    innerDataType={"tovarlar"}
                    document={true}
                  ></MiqdorSelect>
                  <ShowAllBtn></ShowAllBtn>
                </div>
              ),
            },
            // {
            //   title: <div className="table_head">Блок сони</div>,
            //   dataIndex: "blok_soni",
            //   key: "nomi",
            // },
            // {
            //   title: <div className="table_head">Нарх тури</div>,
            //   dataIndex: "valyuta_turi",
            //   key: "valyuta_turi",
            //   render: text => (text === 1 ? "доллар" : "сўм"),
            // },
            {
              title: <div className="table_head">Миқдор</div>,
              dataIndex: "miqdor",
              key: "nomi",
              width: 100,
              render: text => (
                <MiqdorInput name="miqdor" value={text}></MiqdorInput>
              ),
            },
            {
              title: <div className="table_head">Кирим нархи</div>,
              dataIndex: "kirim_narxi",
              key: "nomi",
              render: text => (
                <MiqdorInput name="kirim_narxi" value={text}></MiqdorInput>
              ),
              width: "130px",
            },
            {
              title: <div className="table_head">Сотиш нархи</div>,
              dataIndex: "sotish_narxi",
              key: "nomi",
              render: text => (
                <MiqdorInput name="sotish_narxi" value={text}></MiqdorInput>
              ),
              width: "130px",
            },

            {
              title: <div className="table_head">Кирим сумма</div>,
              dataIndex: "kirim_narxi",
              key: "nomi",
              width: 150,
              align: "right",
              render: (text, record) =>
                text * record.miqdor ? NumberFormat(text * record.miqdor) : "",
            },
            {
              title: <div className="table_head">Сотиш сумма</div>,
              dataIndex: "sotish_narxi",
              key: "nomi",
              width: 150,
              align: "right",
              render: (text, record) =>
                text * record.miqdor ? NumberFormat(text * record.miqdor) : "",
            },
          ],
        },
      ],
    },
  ],
};

export default MaxsulotQoldigi;
