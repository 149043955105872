import React from "react";
import CurrencyInput from "react-currency-input-field";
import {connect} from "react-redux";
import {setDocument, setValues} from "../../../../Actions/AppActions";

const MiqdorInput = ({setDocument, name, record, value, values, setValues}) => {

    const setJamiToValues = async (value, name) => {
        setDocument({
            target: {
                name: name,
                value: value,
            }
        });
    }

    return (
        <CurrencyInput
            value={value}
            name={name}
            decimalsLimit={2}
            decimalSeparator="."
            className="currency_input"
            onValueChange={
                setJamiToValues
            }
        />
    );
};


const mapStateToProps = state => {
    return {
        values: state.app.secondaryModal.open
            ? state.app.values2
            : state.app.values,
    };
};
export default connect(mapStateToProps, {setDocument, setValues})(MiqdorInput);
