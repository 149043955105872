import React from "react";
import "antd/dist/antd.css";
import "./App.css";
import Main from "./Pages/Main";
import { Route, Switch } from "react-router-dom";
import PrintContainer from "./Pages/Print/PrintContainer";
import HisobotContainer from "./Pages/Print/HisobotContainer";
import NakladContainer from "./Pages/Print/NakladContainer";
import RestrTolovContainer from "./Pages/Print/RestrTolovContainer";

function App() {
  return (
    <Switch>
      <Route exact path="/print">
        <PrintContainer type="sotuv"/>
      </Route>
      <Route exact path="/print-vozvrat">
        <PrintContainer type="vozvrat"/>
      </Route>
      <Route exact path="/print-hisobot">
        <HisobotContainer/>
      </Route>
      <Route exact path="/hisobot/naklad">
        <NakladContainer/>
      </Route>
      <Route exact path="/hisobot/restrTolov">
        <RestrTolovContainer/>
      </Route>
      <Route path="/">
        <Main/>
      </Route>
    </Switch>
  );
}

export default App;
