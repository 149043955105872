import {Button, message} from "antd";
import {useEffect} from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    changeAllDocument,
    getInnerData,
    getMainTableData,
    setLoading,
    setRowId,
    setMainTableData,
    setValues,
    setValues3, stateDetector, setRowId2,
} from "../../../Actions/AppActions";
import FetchApi from "../../../Functions/fetchApi";
import ModalInputs from "../../Modals/ModalInputs";
import DocumentClass from "../../../Functions/DocumentClass";

const SmallModalForm = ({
                            values,
                            clearModal,
                            setLoading,
                            modal,
                            getMainTableData,
                            setValues3,
                            setRowId,
                            setRowId2,
                            currentPage,
                            setValues,
                            allData,
                            secondaryModal,
                            innerData,
                            changeAllDocument,
                            rowId,
                            stateDetector,
                            rowId2,
                            actualValues,
                            setColumns,
                            getInnerData,
                        }) => {

    useEffect(async () => {
        let user_id = null;
        if (JSON.parse(window.localStorage.getItem("user")) &&
            JSON.parse(window.localStorage.getItem("user")).Id) {
            user_id = JSON.parse(window.localStorage.getItem("user")).Id;
        }
        const response = await FetchApi("/spravochnik/detali", "GET", {user_id: user_id});
        if (response && response.status === 200) {
            let obj = {};
            if (!rowId2) {
                obj = {
                    ...values,
                    barcode: "" + (response.data[0] && +response.data[0].barcode + 1 || 250000000000),
                }
            } else {
                obj = {
                    ...values,
                }
            }
            // setValues3(obj)
        }
        if (modal && currentPage.innerData) {
            getInnerData([currentPage.innerData]);
        }
    }, [modal]);

    const {location} = useHistory();
    const history = useHistory();
    const [loadings, setLoadings] = useState(false);
    const key = 32432;

    const handleSubmit = async () => {
        const requiredInputs = [];
        currentPage.forms.forEach(el => {
            el.inputs.forEach(d => {
                if (d.required) {
                    requiredInputs.push(d);
                }
            });
        });
        let error = [];
        requiredInputs.forEach(d => {
            if (!(values[d.name] && values[d.name] !== "")) {
                error.push(`${d.label} kiriting!`);
            }
        });
        if (error.length > 0) {
            message.error({content: error[0], key: key});
        } else {
            clearModal();
            // setRowId(null);
            setRowId2(null);
            // console.log(values);
            await RequestJson(
                "/spravochnik/detali",
                values,
                getMainTableData
            );
            await reloadTable();
        }
    };
    const reloadTable = () => {
        const obj = currentPage.forms?.find(f => f.type === "document").inputs[0].innerModal;
        setColumns(obj.columns);
        getInnerData([currentPage.innerData]);
        // setValues3({
        //     ...values,
        //     postavshik: ""
        // })
    }
    const elementChangeDocument = () => {
        const document = actualValues.document;
        let newDocument = [];
        if (document) {
            newDocument = document.map(doc => {
                if (doc.Id == rowId) {
                    return {
                        ...doc,
                        ...values,
                        Id: rowId,
                        element_id: values.Id,
                    };
                } else return doc;
            });

            changeAllDocument(newDocument);
        }
    };

    const RequestJson = async (url, data, refreshCallback, closeModal) => {
        let user_id = null;
        if (JSON.parse(window.localStorage.getItem("user")) &&
            JSON.parse(window.localStorage.getItem("user")).Id) {
            user_id = JSON.parse(window.localStorage.getItem("user")).Id;
        }
        setLoadings(true);
        // await setValues(
        //     {
        //         ...values,
        //         jami: DocumentClass(values.document, "postupleniya-foot").jami_miqdor + "",
        //     });
        const response = await FetchApi(url, "POST", {...data, user_id: user_id});
        if (response && response.status === 200) {
            // refreshCallback && refreshCallback(url);
            // refreshCallback && elementChangeDocument();
            if (closeModal) {
                clearModal();
            }
        }
        setLoadings(false);
    };

    return (
        <>
            <form onSubmit={e => {
                e.preventDefault();
                handleSubmit();
            }}>
                <div className="form_container">
                    {
                        <ModalInputs forms={currentPage.innerSmallModal.forms}>
                            {" "}
                        </ModalInputs>
                    }
                </div>
            </form>
            <div className="form_submit">
                <div style={{display: "flex", justifyContent: "center", gridGap: "10px"}}>
                    {
                        <>
                            <Button className={"form-btn btn-back"} onClick={() => clearModal()}>
                                Ортга
                            </Button>
                            <Button
                                loading={loadings}
                                className={"form-btn btn-submit"}
                                onClick={handleSubmit}>
                                Сақлаш
                            </Button>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        values: state.app.values3,
        modal: state.app.innerSmallModal,
        actualValues: state.app.values,
        innerData: state.app.innerData,
        mainTableData: state.app.mainTableData,
        rowId: state.app.rowId,
        rowId2: state.app.rowId2,
        currentPage: state.app.currentPage,
        allData: state.app.allData,
        secondaryModal: state.app.secondaryModal,
    };
};

export default connect(mapStateToProps, {
    setMainTableData,
    getMainTableData,
    setLoading,
    setValues3,
    setValues,
    stateDetector,
    setRowId,
    setRowId2,
    changeAllDocument,
    getInnerData,
})(SmallModalForm);
