import React, { useState } from "react";
import { connect } from "react-redux";
import {
  COSTUM,
  DATE,
  SELECT,
  NUMBER
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import ModalInputs from "../../Component/Modals/ModalInputs";
import TableGen from "../../Functions/TableGen";
import Refresh from "../Hisobot/components/Refresh";
import BackButton from "../Savdo/Components/BackButton";
import TableBody from "./TableBody";

const NakladContainer = ({ allData, hisobot, values }) => {
  const [onPrint, setOnPrint] = useState(true);

  const forms = [
    {
      grid: "1fr 1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Сана",
          name: "sana",
          type: DATE,
        },
        {
          label: "Сана 2",
          name: "sana2",
          secondDate: true,
          type: DATE,
        },
        {
          label: "№-дан",
          name: "id1",
          type: NUMBER,
        },
        {
          label: "№-гача",
          name: "id2",
          type: NUMBER,
        },
        {
          label: "Агент",
          name: "agent_id",
          type: SELECT,
          allData: {
            type: "agentlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          label: "Мижоз",
          name: "mijoz_id",
          type: SELECT,
          allData: {
            type: "mijozlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          label: "Махсулотлар",
          name: "maxsulot_id",
          type: SELECT,
          allData: {
            type: "tovarlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          type: COSTUM,
          component: (
            <div
              style={{ display: "flex", gridGap: "10px", alignItems: "center" }}
            >
              <Refresh text="Янгилаш"></Refresh>
              <BackButton></BackButton>
            </div>
          ),
        },
      ],
    },
  ];

  if (hisobot) {
    let data = [];

    if (values.maxsulot_id) {
      const filteredH = hisobot.map(i => {
        return {
          ...i,
          document: i.document.filter(d => d.element_id == values.maxsulot_id),
        };
      });
      data = filteredH.filter(d => d.document.length > 0);
    } else {
      data = hisobot;
    }

    const table = {
      thead: [
        {
          className: "thead-th-bg-naklad",
          data: [
            {
              value: "№",
              styles: {
                fontFamily: "Arial, Helvetica, sans-serif",
                fontSize: "13px",
                fontWeight: "600",
              },
            },
            {
              value: "Сана",
              styles: {
                fontFamily: "Arial, Helvetica, sans-serif",
                fontSize: "13px",
                fontWeight: "600",
              },
            },
            {
              value: "Мижоз",
              cols: 2,
              styles: {
                fontFamily: "Arial, Helvetica, sans-serif",
                fontSize: "13px",
                fontWeight: "600",
              },
            },
            {
              value: "Агент",
              cols: 2,
              styles: {
                fontFamily: "Arial, Helvetica, sans-serif",
                fontSize: "13px",
                fontWeight: "600",
              },
            },
            {
              value: "Сумма",
              styles: {
                fontFamily: "Arial, Helvetica, sans-serif",
                fontSize: "13px",
                fontWeight: "600",
              },
            },
          ],
        },
      ],
      tbody: TableBody(data, allData, values.maxsulot_id ? true : false),
    };

    window.onbeforeprint = function () {
      setOnPrint(false);
    };
    window.onafterprint = function () {
      setOnPrint(true);
    };

    return (
      <>
        {onPrint ? (
          <div style={{ padding: "10px", borderBottom: "2px solid black" }}>
            <ModalInputs forms={forms}> </ModalInputs>
          </div>
        ) : null}
        <div style={{ width: "900px" }}>
          <TableGen
            table={table}
            title="Махсулотлар савдосини рўйхатга олиш журнали"
          ></TableGen>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            padding: "10px",
          }}
        >
          <ModalInputs forms={forms}> </ModalInputs>
        </div>
      </>
    );
  }
};
const mapStateToProps = state => {
  return {
    allData: state.app.allData,
    hisobot: state.app.hisobot,
    values: state.app.values,
  };
};

export default connect(mapStateToProps)(NakladContainer);
