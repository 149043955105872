import React from "react";
import "../skladHeader.scss";
import {Button, Select} from "antd";
import {Option} from "antd/lib/mentions";
import {connect} from "react-redux";
import {
    getAllData,
    getHisobot, setInnerDataType2, setInnerModal2, setValues,
} from "../../../Actions/AppActions";
import TextArea from "antd/es/input/TextArea";

const ZborkaHeaderBody = ({currentPage, allData, values, setValues, setInnerModal2, setInnerDataType2}) => {

    const changeSklad = (e) => {
        setValues({...values, sklad: e.target.value})
    }

    const changeMebel = (e) => {
        let modelName = allData["mebeli"]?.find(el => el.Id === e.target.value).model;
        let modelId = allData["modeli"]?.find(el => el.nomi === modelName).Id;
        setValues({...values, mebel: e.target.value, model: modelId})
    }

    const changeModel = (e) => {
        setValues({...values, model: e.target.value})
    }

    const changeComment = (e) => {
        setValues({...values, izoh: e.target.value})
    }

    const openNewHeadModal = (head) => {
        setInnerModal2(true);
        setInnerDataType2(head);
    }

    return (
        <>
            <div className="sklad-head-body">
                {/*<div>*/}
                {/*</div>*/}
                <div style={{display: "flex", minWidth: "230px"}}>
                    <span className="cap-text">Склад</span>
                    <Select
                        placeholder="Склады"
                        showSearch
                        className="sklad-select"
                        value={values["sklad"] || null}
                        required={true}
                        allowClear={true}
                        // loading={loading2}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={value => {
                            const v = {
                                target: {
                                    name: "sklad",
                                    value: value,
                                },
                            };
                            changeSklad(v)
                        }}>
                        {
                            allData && allData.skladi ? allData.skladi.map(d => (
                                <Option key={d.Id} value={d.Id}>
                                    {d.nomi}
                                </Option>
                            )) : ""
                        }
                    </Select>
                    <Button onClick={() => openNewHeadModal(currentPage.header.skladi)}>...</Button>
                </div>
                {/*<div>*/}
                {/*</div>*/}
                <div style={{display: "flex"}}>
                    <span className="cap-text">Мебел</span>
                    <Select
                        placeholder="Мебел"
                        showSearch
                        className="mebel-select"
                        value={values["mebel"] || null}
                        required={true}
                        allowClear={true}
                        // loading={loading2}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={value => {
                            const v = {
                                target: {
                                    name: "mebel",
                                    value: value,
                                },
                            };
                            changeMebel(v)
                        }}>
                        {
                            allData && allData.mebeli ? allData.mebeli.map(d => (
                                <Option key={d.Id} value={d.Id}>
                                    {d.nomi}
                                </Option>
                            )) : ""
                        }
                    </Select>
                    <Button onClick={() => openNewHeadModal(currentPage.header.mebeli)}>...</Button>
                </div>
                {/*<div>*/}
                {/*</div>*/}
                <div style={{display: "flex"}}>
                    <span className="cap-text">Модел</span>
                    <Select
                        placeholder="Модел"
                        showSearch
                        className="model-select"
                        value={values["model"] || null}
                        required={true}
                        allowClear={true}
                        // loading={loading2}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={value => {
                            const v = {
                                target: {
                                    name: "model",
                                    value: value,
                                },
                            };
                            changeModel(v)
                        }}>
                        {
                            allData && allData.modeli ? allData.modeli.map(d => (
                                <Option key={d.Id} value={d.Id}>
                                    {d.nomi}
                                </Option>
                            )) : ""
                        }
                    </Select>
                    <Button onClick={() => openNewHeadModal(currentPage.header.modeli)}>...</Button>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.app.allData,
        currentPage: state.app.currentPage,
        values: state.app.secondaryModal.open
            ? state.app.values2
            : state.app.values,
    };
};
export default connect(mapStateToProps, {
    getHisobot,
    getAllData,
    setInnerModal2,
    setInnerDataType2,
    setValues,
})(ZborkaHeaderBody);
