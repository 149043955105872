import React from "react";
import {connect} from "react-redux";
import {setSmallModal, setValues2} from "../../Actions/AppActions";
import "./Modal.css";
import Modal from "antd/lib/modal/Modal";
import {RiCloseCircleFill} from "react-icons/ri";
import SmallModalForm from "../Actions/secondActions/SmallModalForm";

function SmallModal({setSmallModal, innerSmallModal, setColumns, setValues2}) {
    const clearModal = () => {
        // innerSetsmallModal({
        //     title: "",
        //     open: false,
        //     path: "",
        //     forms: [],
        // });
        setSmallModal(false)
        // setValues2({});
    };

    return (
        <Modal
            centered
            maskClosable={false}
            keyboard={false}
            title={
                <div className="modal-title">
                    <span>Детал</span>
                </div>
            }
            width="850px"
            onCancel={clearModal}
            visible={innerSmallModal}
            footer={null}
            closeIcon={<RiCloseCircleFill/>}>
            <SmallModalForm clearModal={clearModal} setColumns={setColumns}/>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        innerSmallModal: state.app.innerSmallModal,
    };
};
export default connect(mapStateToProps, {
    setSmallModal,
    setValues2,
})(SmallModal);
