import {BaseUrl} from "../../BaseUrl";
import {RiFileDownloadFill} from "react-icons/ri";
import {
    DATE,
    MODALTABLE,
    SELECT,
    STRING,
    TEXTBLOCK,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";
import CheckboxMiqdor from "../../Component/Tables/ModalTable/KirimChiqimInputs/CheckboxMiqdor";
import DocumentClass from "../../Functions/DocumentClass";
import MiqdorOstatka from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorOstatka";
import SanaFormat from "../../Functions/SanaFormat";
import NumberFormat from "../../Functions/NumberFormat";
import KirimInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/KirimInput";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import ShowAllBtn from "./Components/ShowAllBtn";

const Продажа = {
    title: "Продажа",
    path: "/prodaja",
    type: SIMPLE,
    icon: <RiFileDownloadFill/>,
    modalWidth: "1336px",
    innerData: "tovarlar",
    modalJami: "maxsulot-kirimi",
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">ID</div>,
            dataIndex: "Id",
            width: "100px",
            align: "center",
        },
        {
            title: <div className="table_head">Сана</div>,
            dataIndex: "time_create",
            key: "nomi",
            width: "160px",
            render: text => SanaFormat(text),
        },
        {
            title: <div className="table_head">Юк берувчи</div>,
            dataIndex: "yuk_beruvchilar",
            key: "nomi",
            width: "200px",
        },
        {
            title: <div className="table_head">Қарзи</div>,
            dataIndex: "qarzi_som",
            key: "nomi",
            width: "170px",
            render: text => NumberFormat(text, 2),
        },
        {
            title: <div className="table_head">Жами миқдор</div>,
            dataIndex: "document",
            key: "nomi",
            render: text => DocumentClass(text, "maxsulot-kirimi").miqdor_soni,
        },
        {
            title: <div className="table_head">Жами сумма</div>,
            children: [
                {
                    title: <div className="table_head">сўм</div>,
                    dataIndex: "document",
                    key: "nomi",
                    render: text =>
                        NumberFormat(
                            isNaN(DocumentClass(text, "maxsulot-kirimi").kirim_summa)
                                ? 0
                                : NumberFormat(
                                    DocumentClass(text, "maxsulot-kirimi").kirim_summa
                                )
                        ),
                },
                // {
                //   title: <div className="table_head">вал</div>,
                //   dataIndex: "document",
                //   key: "nomi",
                //   render: text =>
                //     isNaN(DocumentClass(text, "maxsulot-kirimi").kirim_val_summa)
                //       ? 0
                //       : NumberFormat(
                //           DocumentClass(text, "maxsulot-kirimi").kirim_val_summa,
                //           1
                //         ),
                // },
            ],
        },
        {
            title: <div className="table_head">Изох</div>,
            dataIndex: "izoh",
            key: "nomi",
            width: "178px",
        },
    ],
    allData: [
        {value: "yuk_beruvchi_id", prop: "yuk_beruvchilar", get: "fio"},
        {value: "maxsulot_id", prop: "tovarlar", get: "nomi"},
        {value: "constanta", prop: "constanta", get: "kurs"},
    ],
    forms: [
        {
            type: "document",
            grid: "1fr",
            actionInputs: [
                {
                    grid: "2fr 1fr 1fr 2fr 3fr",
                    inputs: [
                        {
                            label: "Юк берувчи",
                            name: "yuk_beruvchi_id",
                            type: SELECT,
                            required: true,
                            allData: {
                                type: "yuk_beruvchilar",
                                key: "Id",
                                text: "fio",
                            },
                        },
                        {
                            label: "Қарзи сўм",
                            name: "qarzi_som",
                            type: TEXTBLOCK,
                        },
                        // {
                        //   label: "Қарзи валюта",
                        //   name: "qarzi_val",
                        //   type: TEXTBLOCK,
                        // },
                        {
                            label: "Сана",
                            name: "time_create",
                            type: DATE,
                        },
                        {
                            label: "Изох",
                            name: "izoh",
                            type: STRING,
                        },
                    ],
                },
            ],
            inputs: [
                {
                    name: "document",
                    type: MODALTABLE,
                    innerModal: {
                        title: "Маҳсулотлар",
                        allData: "tovarlar",
                        columns: [
                            {
                                title: <div className="table_head">№</div>,
                                key: "index",
                                render: (text, record, index) => index + 1,
                                width: "50px",
                                align: "center",
                            },
                            {
                                title: <div className="table_head">Маҳсулот</div>,
                                dataIndex: "nomi",
                                key: "nomi",
                                width: "200px",
                            },
                            {
                                title: <div className="table_head">Блок сони</div>,
                                dataIndex: "blok_soni",
                                key: "blok_soni",
                                width: "101px",
                            },
                            // {
                            //   title: <div className="table_head">Валюта тури</div>,
                            //   dataIndex: "valyuta_turi",
                            //   key: "blok_soni",
                            //   width: "101px",
                            //   render: text => (text == 1 ? "Доллар" : "Сўм"),
                            // },
                            {
                                title: <div className="table_head">Кирим нархи</div>,
                                dataIndex: "kirim_narxi",
                                width: "101px",
                            },
                            {
                                title: <div className="table_head">Сотиш нархи</div>,
                                dataIndex: "sotish_narxi",
                                width: "101px",
                            },
                            {
                                title: <div className="table_head">Расми</div>,
                                dataIndex: "img",
                                key: "img",
                                render: text => {
                                    if (text) {
                                        return (
                                            <picture>
                                                <source
                                                    media="max-width: 200px"
                                                    srcSet={BaseUrl + text.slice(1)}
                                                />
                                                <img
                                                    src={BaseUrl + text.slice(1)}
                                                    alt="asda"
                                                    height={30}
                                                />
                                            </picture>
                                        );
                                    } else return "";
                                },
                            },
                        ],
                    },

                    costumColumns: [
                        {
                            title: <div className="table_head">№</div>,
                            key: "index",
                            render: (text, record, index) => index + 1,
                            width: "70px",
                            align: "center",
                        },
                        {
                            title: <div className="table_head">Маҳсулот</div>,
                            dataIndex: "element_id",
                            key: "nomi",
                            render: text => (
                                <div style={{display: "flex", alignItems: "flex-end"}}>
                                    <MiqdorSelect
                                        name={"element_id"}
                                        value={text}
                                        placeholder="Махсулот"
                                        get="nomi"
                                        cursor="1"
                                        innerDataType={"tovarlar"}
                                        document={true}
                                    ></MiqdorSelect>
                                    <ShowAllBtn></ShowAllBtn>
                                </div>
                            ),
                        },
                        // {
                        //   title: <div className="table_head">Блок сони</div>,
                        //   dataIndex: "blok_soni",
                        //   key: "nomi",
                        //   align: "center",
                        //   width: "70px",
                        // },
                        // {
                        //   title: <div className="table_head">Нарх тури</div>,
                        //   dataIndex: "valyuta_turi",
                        //   key: "valyuta_turi",
                        //   width: "80px",
                        //   render: text => (text === 1 ? "доллар" : "сўм"),
                        // },
                        {
                            title: <div className="table_head">Миқдор</div>,
                            children: [
                                {
                                    title: <div className="table_head">блок</div>,
                                    dataIndex: "miqdor_blok",
                                    key: "nomi",
                                    width: "120px",
                                    render: (text, record) => (
                                        <MiqdorOstatka
                                            name="miqdor_blok"
                                            type="blok"
                                            blok_soni={record.blok_soni}
                                            value={text}
                                        ></MiqdorOstatka>
                                    ),
                                },
                                {
                                    title: <div className="table_head">сони</div>,
                                    dataIndex: "miqdor_soni",
                                    key: "nomi",
                                    width: "150px",
                                    render: (text, record) => (
                                        <MiqdorOstatka
                                            name="miqdor_soni"
                                            type="name"
                                            blok_soni={record.blok_soni}
                                            value={text}
                                        ></MiqdorOstatka>
                                    ),
                                },
                            ],
                        },

                        {
                            title: <div className="table_head">Кирим нархи</div>,
                            children: [
                                {
                                    title: <div className="table_head">сўм</div>,
                                    dataIndex: "kirim_narxi",
                                    key: "nomi",
                                    width: "190px",
                                    render: (text, record) => (
                                        <KirimInput
                                            name="kirim_narxi"
                                            record={record}
                                            value={text}
                                        ></KirimInput>
                                    ),
                                },
                                // {
                                //   title: <div className="table_head">вал</div>,
                                //   dataIndex: "kirim_val",
                                //   key: "nomi",
                                //   width: "150px",
                                //   render: (text, record) => (
                                //     <KirimInput
                                //       name="kirim_val"
                                //       record={record}
                                //       value={text}
                                //     ></KirimInput>
                                //   ),
                                // },
                            ],
                        },
                        // {
                        //   title: <div className="table_head">Наценка %</div>,
                        //   width: "100px",
                        //   dataIndex: "chegirma",
                        //   render: (text, record) => (
                        //     <KirimInput
                        //       name="chegirma"
                        //       record={record}
                        //       value={text}
                        //     ></KirimInput>
                        //   ),
                        // },
                        {
                            title: <div className="table_head">Сотиш нархи</div>,
                            children: [
                                {
                                    title: <div className="table_head">сўм</div>,
                                    dataIndex: "sotish_narxi",
                                    key: "nomi",
                                    width: "190px",
                                    render: (text, record) => (
                                        <KirimInput
                                            name="sotish_narxi"
                                            record={record}
                                            value={text}
                                        ></KirimInput>
                                    ),
                                },
                                // {
                                //   title: <div className="table_head">вал</div>,
                                //   dataIndex: "sotish_val",
                                //   key: "nomi",
                                //   width: "150px",
                                //   render: (text, record) => (
                                //     <KirimInput
                                //       name="sotish_val"
                                //       record={record}
                                //       value={text}
                                //     ></KirimInput>
                                //   ),
                                // },
                            ],
                        },

                        {
                            title: <div className="table_head">Кирим сумма</div>,
                            children: [
                                {
                                    title: <div className="table_head">сўм</div>,
                                    dataIndex: "kirim_narxi",
                                    key: "nomi",
                                    width: "150px",
                                    render: (text, record) =>
                                        NumberFormat(text * record.miqdor_soni),
                                },
                                // {
                                //   title: <div className="table_head">вал</div>,
                                //   dataIndex: "kirim_val",
                                //   key: "nomi",
                                //   width: "150px",
                                //   render: (text, record) =>
                                //     NumberFormat(text * record.miqdor_soni),
                                // },
                            ],
                        },
                        {
                            title: <div className="table_head">Запис</div>,
                            dataIndex: "zapis",
                            key: "nomi",
                            width: 70,
                            render: text => (
                                <CheckboxMiqdor name="zapis" value={text}></CheckboxMiqdor>
                            ),
                        },
                    ],
                },
            ],
        },
    ],
};

export default Продажа;
