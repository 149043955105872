import {
    Button,
    DatePicker,
    Input,
    InputNumber,
    message,
    Popconfirm,
    Radio,
    Select,
} from "antd";
import {Option} from "antd/lib/mentions";
import React, {useEffect, useRef} from "react";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "./ImageUpload";
import {
    BARCODE,
    BARCODE_INPUT,
    BUTTON_MIJOZ,
    COSTUM,
    CURRENCY,
    DATE,
    IMAGE,
    MAP,
    MIJOZ_OTKAZ,
    MODALTABLE,
    NUMBER,
    PHONE,
    RADIO,
    SELECT,
    STRING,
    TEXTBLOCK,
} from "./ModalInputTypes";
import "./Input.css";
import {connect} from "react-redux";
import ModalTable from "../../Tables/ModalTable/ModalTable";
import moment from "moment";
import {changeAllDocument} from "../../../Actions/AppActions";
import {useState} from "react";
import fetchApi from "../../../Functions/fetchApi";
import CurrencyInput from "react-currency-input-field";
import NumberFormat from "../../../Functions/NumberFormat";
import MapContainer from "../../../Pages/ServicePages/Components/MapContainer";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";

const ModalInput = ({
                        type,
                        label,
                        name,
                        onChange,
                        values,
                        allData,
                        allDataType,
                        costumData,
                        modal,
                        costumColumns,
                        condition,
                        changeAllDocument,
                        component,
                        values3,
                        readOnly,
                        autoGetFocus,
                        addBefore,
                        className,
                        mainTableData,
                        currentPage,
                        loading2,
                        secondDate,
                    }) => {
    let input = null;
    const autoFocus = useRef(null);

    useEffect(() => {
        if (modal && autoFocus && autoGetFocus) {
            autoFocus && autoFocus.current && autoFocus.current.focus();
        }
    }, [modal]);

    function onSearch() {
    }

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const inpt = useRef();

    const qabulQilish = () => {
        const docs = values.document;
        let mapedDocs = [];
        if (docs) {
            mapedDocs = docs.map(d => {
                let sotish_soni = 0;
                if (d.qoldiq >= d.miqdor_soni) {
                    sotish_soni = d.miqdor_soni;
                } else {
                    sotish_soni = d.qoldiq;
                }
                return {...d, sotish_soni: sotish_soni};
            });

            changeAllDocument(mapedDocs);
        }
    };

    const onBlur = () => {
        if (currentPage.path == "/servis/maxsulot" && values.nomi) {
            if (
                mainTableData.find(
                    d =>
                        d.nomi.toLowerCase().replace(/\s+/g, "") ===
                        values.nomi.toLowerCase().replace(/\s+/g, "")
                )
            ) {
                message.warning("Бундай товар мавжуд");
            }
        }
    };
    switch (type) {
        case STRING:
            input = (
                <Input
                    name={name}
                    autocomplete="off"
                    onBlur={onBlur}
                    ref={autoGetFocus ? autoFocus : null}
                    addonBefore={addBefore}
                    value={values[name]}
                    onChange={onChange}
                />
            );
            break;
        case COSTUM:
            input = component;
            break;
        case NUMBER:
            input = (
                <InputNumber
                    min={0}
                    type="number"
                    readOnly={readOnly ? readOnly : false}
                    value={
                        allDataType
                            ? allData[allDataType]?.length > 0 &&
                            allData[allDataType][0][name]
                            : values[name]
                    }
                    onChange={value => {
                        const v = {
                            target: {
                                name: name,
                                value: value,
                            },
                        };
                        onChange(v);
                    }}
                />
            );
            break;
        case SELECT:
            input = (
                <Select
                    defaultValue={label + "ни танланг"}
                    value={values[name]}
                    showSearch
                    allowClear={true}
                    loading={loading2}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={value => {
                        const v = {
                            target: {
                                name: name,
                                value: value,
                            },
                        };
                        onChange(v);
                    }}>
                    {
                        costumData ? costumData.map(d => (
                                <Option key={d.Id} value={d.Id}>
                                    {d.nomi}
                                </Option>
                            ))
                            : allDataType.dependent
                                ? allData[allDataType.type]
                                    ?.filter(
                                        d => d[allDataType.dependent] == values[allDataType.dependent]
                                    )
                                    .map(m => (
                                        <Option key={m[allDataType.key]} value={m[allDataType.key]}>
                                            {m[allDataType.text]}
                                        </Option>
                                    ))
                                : allData[allDataType.type]
                                    ?.filter(d => {
                                        if (allDataType.filters) {
                                            if (allDataType.filters.condition == "greater") {
                                                return (
                                                    d[allDataType.filters.prop] > allDataType.filters.value
                                                );
                                            }
                                        } else return true;
                                    })
                                    .map(m => (
                                        <Option key={m[allDataType.key]} value={m[allDataType.key]}>
                                            {m[allDataType.text]}
                                        </Option>
                                    ))}
                </Select>
            );
            break;
        case PHONE:
            input = (
                <PhoneInput
                    country={"uz"}
                    inputStyle={{
                        width: "100%",
                        border: "1px solid #ccc",
                        padding: "4px 11px",
                        borderRadius: "var(--input-radius)",
                        backgroundColor: "var(--input-bg)",
                    }}
                    specialLabel={false}
                    disableDropdown={true}
                    countryCodeEditable={false}
                    value={values[name] ? values[name] : "+998"}
                    areaCodes={{
                        uz: ["+998"],
                    }}
                    masks={{uz: "(..) ...-..-.."}}
                    prefix="+"
                    onChange={phone => {
                        const e = {
                            target: {
                                name: name,
                                value: phone,
                            },
                        };

                        onChange(e);
                    }}
                />
            );
            break;
        case BARCODE:
            var Barcode = require("react-barcode");
            input = (
                <Barcode
                    name={name}
                    width={1}
                    height={"40px"}
                    margin={0}
                    displayValue={false}
                />
            );
            break;
        case BARCODE_INPUT:
            input = (
                <Input
                    name={name}
                    ref={inpt}
                    type="text"
                    disabled={readOnly}
                    onBlur={onBlur}
                    value={values3[name] || values[name]}
                    onChange={onChange}
                />
            );
            break;
        case IMAGE:
            input = <ImageUpload onImage={onChange}/>;
            break;
        case RADIO:
            input = (
                <div style={{marginTop: "5px"}}>
                    <Radio.Group onChange={onChange} name="turi" value={values.turi}>
                        {costumData.map(d => (
                            <Radio value={d.Id}>{d.nomi}</Radio>
                        ))}
                    </Radio.Group>
                </div>
            );
            break;
        case MODALTABLE:
            input = (
                <ModalTable innerTable={false} columns={costumColumns}/>
            );
            break;
        case TEXTBLOCK:
            input = (
                <div style={{
                    color: "red",
                }}>
                    {NumberFormat(values[name], name === "qarzi_val" && 1)}
                </div>
            );
            break;
        case BUTTON_MIJOZ:
            input = (
                <Button onClick={qabulQilish} type="primary">
                    {name}
                </Button>
            );
            break;
        case DATE:
            input = (
                <DatePicker
                    showTime
                    value={
                        values[name]
                            ? moment(values[name])
                            : secondDate
                                ? moment().set("hour", 23).set("minute", 59)
                                : moment().set("hour", 0).set("minute", 1)
                    }
                    format="DD.MM.YYYY HH:mm"
                    allowClear={false}
                    locale={locale}
                    onChange={v => {
                        const e = {
                            target: {
                                name: name,
                                value: moment(v).format("YYYY-MM-DD HH:mm:ss"),
                            },
                        };

                        onChange(e);
                    }}
                />
            );
            break;
        case MAP:
            input = <MapContainer/>;
            break;

        case CURRENCY:
            input = (
                <CurrencyInput
                    id="input-example"
                    name={name}
                    decimalSeparator="."
                    readOnly={readOnly ? readOnly : false}
                    className="currency_input"
                    value={values[name] ? values[name] : ""}
                    decimalsLimit={2}
                    onValueChange={(value, name) => {
                        const e = {
                            target: {
                                name: name,
                                value: value,
                            },
                        };

                        onChange(e);
                    }}
                />
            );
            break;
        default:
            break;
    }

    if (condition && values[condition.prop] != condition.value) {
        return null;
    } else {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}>
                {
                    label ?
                        <lable className="label">{label}</lable>
                        :
                        <lable className="label" style={{visibility: "hidden"}}>
                            _
                        </lable>
                }
                <div className="form_input">{input}</div>
            </div>
        );
    }
};
const mapStateToProps = state => {
    return {
        values: state.app.innerSmallModal ? state.app.values3 :
            state.app.secondaryModal.open ? state.app.values2 : state.app.values,
        allData: state.app.allData,
        values3: state.app.values3,
        mainTableData: state.app.mainTableData,
        currentPage: state.app.currentPage,
        loading2: state.app.loading,
        modal: state.app.modal,
    };
};
export default connect(mapStateToProps, {changeAllDocument})(ModalInput);
