import DocumentClass from "./DocumentClass";

export default (turi, data) => {
    switch (turi) {
        case "postupleniya-foot":
            return [
                "",
                "",
                DocumentClass(data, turi).jami_miqdor,
                "",
                "",
            ];
        case "zborka-foot":
            return [
                "",
                "",
                "",
                "",
                DocumentClass(data, turi).jami_miqdor,
                "",
            ];
        case "maxsulot-qoldigi":
            const arrmax = [
                "",
                // "",
                // "",
                DocumentClass(data, turi).miqdor_summa,
                "",
                "",
                DocumentClass(data, turi).kirim_summa,
                DocumentClass(data, turi).sotish_summa,
            ];
            return arrmax;
        default:
            break;
    }
};
