import NumberFormat from "../../Functions/NumberFormat";
import SanaFormat from "../../Functions/SanaFormat";

export default (hisobot = [], allData, summary) => {
  const body = [];
  let all_miqdor = 0;
  let all_summa = 0;
  let all_qarzi = 0;
  hisobot?.forEach((d, index2) => {
    body.push({
      br: true,
      className: "tbody-th-bg-naklad",
      data: [
        {
          value: index2 + 1,
          styles: { fontFamily: "Arial, Helvetica, sans-serif" },
        },

        {
          value: allData.agentlar.find(m => m.Id == d.agent_id)
            ? allData.agentlar.find(m => m.Id == d.agent_id).fio
            : null,
          cols: 5,
          styles: { fontFamily: "Arial, Helvetica, sans-serif" },
        },
      ],
    });

    body.push({
      data: [
        {
          value: <div style={{ height: "5px", width: "100%" }}></div>,
          styles: {
            borderRight: "1px solid #fff",
            borderLeft: "1px solid #fff",
          },
        },
      ],
    });
    if (d.document) {
      body.push({
        data: [
          {
            value: "№",
            styles: { borderLeft: "1px solid #000", fontWeight: "600" },
          },
          {
            value: "Вақти",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Мижоз",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Қарздорлик",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Сумма",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Изох",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          { styles: { border: "1px solid #fff", fontWeight: "600" } },
        ],
      });
      let summa_total = 0;
      let total_miqdor = 0;
      d.document?.forEach((d, index) => {
        body.push({
          data: [
            {
              value: index + 1,
              styles: { borderLeft: "1px solid #000", width: "50px" },
            },
            {
              value: SanaFormat(d.time_create),
              styles: {
                textAlign: "left",
                width: "150px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: allData.mijozlar.find(m => m.Id == d.element_id)
                ? allData.mijozlar.find(m => m.Id == d.element_id).fio
                : null,
              styles: {
                width: "300px",
                textAlign: "left",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: d.qarzi_som,
              styles: {
                width: "100px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: d.summa_som,
              styles: {
                width: "100px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: d.izox,
              styles: {
                width: "100px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
          ],
        });
      });
      body.push({
        data: [
          {
            value: "Жами",
            cols: 3,
            styles: {
              textAlign: "right",
              fontWeight: 550,
              borderLeft: "1px solid #000",
            },
          },
          {
            value: "",
          },
          {
            value: NumberFormat(summa_total, 2),
            styles: {
              textAlign: "right",
              fontWeight: 550,
              borderLeft: "1px solid #000",
            },
          },
          {
            value: "",
            styles: {
              borderRight: "1px solid #fff",
              borderBottom: "1px solid #fff",
              color: "blue",
              fontSize: "13px",
            },
          },
        ],
      });

      all_miqdor += total_miqdor;
      all_summa += summa_total;
      all_qarzi += d.qarzi_som * 1 + summa_total * 1;
    }
  });

  // summary
  if (summary) {
    body.push({
      br: true,
      data: [
        {
          value: "Жами",
          cols: 3,
          styles: {
            textAlign: "right",
            fontWeight: 550,
            borderLeft: "1px solid #000",
          },
        },

        {
          value: all_miqdor,
          cols: 1,
          styles: {
            textAlign: "right",
            fontWeight: 550,
            borderLeft: "1px solid #000",
          },
        },
        {
          value: "",
        },
        {
          value: NumberFormat(all_summa, 2),
          styles: {
            textAlign: "center",
            fontWeight: 550,
            borderLeft: "1px solid #000",
          },
        },
        {
          value: NumberFormat(all_qarzi, 2),
          styles: { borderRight: "1px solid #000" },
        },
      ],
    });
  }

  return body;
};
