import {Button, message} from "antd";
import {useEffect} from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    changeAllDocument,
    getInnerData,
    getMainTableData,
    setLoading,
    setRowId,
    setMainTableData,
    setValues,
    setValues3, stateDetector, setRowId2, getInnerHeadData, getAllData, setInnerHeadData,
} from "../../../Actions/AppActions";
import FetchApi from "../../../Functions/fetchApi";
import ModalInputs from "../../Modals/ModalInputs";
import DocumentClass from "../../../Functions/DocumentClass";

const NewHeadForm = ({
                         values,
                         clearModal,
                         getMainTableData,
                         setRowId2,
                         getAllData,
                         innerDataType,
                         currentPage,
                     }) => {

    const [loadings, setLoadings] = useState(false);
    const key = 32432;

    const handleSubmit = async (e) => {
        e.preventDefault()
        const requiredInputs = [];
        innerDataType.mainData.forms.forEach(el => {
            el.inputs.forEach(d => {
                if (d.required) {
                    requiredInputs.push(d);
                }
            });
        });
        let error = [];
        requiredInputs.forEach(d => {
            if (!(values[d.name] && values[d.name] !== "")) {
                error.push(`${d.label} kiriting!`);
            }
        });
        if (error.length > 0) {
            message.error({content: error[0], key: key});
        } else {
            clearModal();
            setRowId2(null);
            await RequestJson(
                innerDataType.mainData.path,
                values,
                getMainTableData
            );
            await getAllData([innerDataType.dataType]);
        }
    };

    const RequestJson = async (url, data, refreshCallback, closeModal) => {
        let user = JSON.parse(window.localStorage.getItem("user"));
        let user_id = user ? user.id : null;

        setLoadings(true);
        const response = await FetchApi(url, "POST", {...data, user_id: user_id});
        if (response && response.status === 200) {
            if (closeModal) {
                clearModal();
            }
        }
        setLoadings(false);
    };

    let customStyle = {
        display: "flex",
        justifyContent: "center",
        gridGap: "10px"
    }
    return (
        <form>
            <div className="form_container">
                <ModalInputs forms={innerDataType.mainData.forms}/>
            </div>
            <div className="form_submit">
                <div style={customStyle}>
                    <Button className={"form-btn btn-back"} onClick={() => clearModal()}>Ортга</Button>
                    <Button loading={loadings}
                            className={"form-btn btn-submit"}
                            onClick={handleSubmit}>Сақлаш</Button>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = state => {
    return {
        values: state.app.values3,
        modal: state.app.innerSmallModal,
        innerData: state.app.innerData,
        innerDataHead: state.app.innerDataHead,
        innerDataType: state.app.innerDataType,
        mainTableData: state.app.mainTableData,
        rowId2: state.app.rowId2,
        currentPage: state.app.currentPage,
        allData: state.app.allData,
    };
};

export default connect(mapStateToProps, {
    setMainTableData,
    getAllData,
    getMainTableData,
    getInnerHeadData,
    setValues,
    setValues3,
    setRowId2,
    setInnerHeadData,
    getInnerData,
})(NewHeadForm);
