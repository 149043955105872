import {
    IMAGE,
    MODALTABLE,
    SELECT,
    STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import {RiVipCrownFill} from "react-icons/ri";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";
import {BaseUrl} from "../../BaseUrl";
import Zoom from "react-medium-image-zoom";

const Model = {
    title: "Модел",
    path: "/spravochnik/modeli",
    icon: <RiVipCrownFill/>,
    type: SIMPLE,
    innerData: "tovarlar",
    modalWidth: "500px",
    innerAll: [{value: "brend_id", prop: "brendlar", get: "nomi"}],
    // defaultDocument: {
    //     allData: "tovarlar",
    //     prop: "brend_id",
    // },
    columns: [
        {
            title: <div className="table_head">№</div>,
            key: "index",
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">Номи</div>,
            dataIndex: "nomi",
            key: "nomi",
            width: "132px",
        },
    ],
    allData: [],
    forms: [
        {
            grid: "1fr",
            inputs: [
                {
                    label: "Номи",
                    name: "nomi",
                    type: STRING,
                    required: true,
                    autoGetFocus: true,
                },
            ],
        },
    ],
};
export default Model;
